@import '../../node_modules/antd/dist/antd'; // Less Entry File
@import 'src/vars';
@import 'src/sdk/components/index';
@import 'src/sdk/components/typography/index';

html,
body {
  height: 100%;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-base);
  color: var(--text-color);
  font-weight: var(--font-weight-base);
}

body {
  background-color: var(--body-background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='email'],
textarea {
  -webkit-appearance: none;
}

::selection {
  color: var(--text-color-inverse);
  background: var(--text-selection-bg);
}

a,
a.@{ant-prefix}-typography {
  color: var(--link-color);
  &:hover {
    color: var(--link-hover-color);
  }
  &:active {
    color: var(--link-active-color);
  }
}

.input-icon {
  height: 40px;
  input {
    display: none;
  }

  span {
    margin: 0;
  }
}
.@{ant-prefix}icon {
  color: inherit;
}

.@{ant-prefix}-badge-count {
  box-shadow: none;
}

.@{ant-prefix}-badge-dot {
  width: 12px;
  height: 12px;
  background: var(--warning-color);
  border: 2px solid var(--border-color-base);
  box-shadow: none;
}

.@{ant-prefix}-badge-count,
.@{ant-prefix}-badge-dot,
.@{ant-prefix}-badge .@{ant-prefix}-scroll-number-custom-component {
  top: 2px;
  right: 2px;
}

.@{ant-prefix}-collapse {
  background: var(--layout-body-background);

  .@{ant-prefix}-collapse-no-arrow .@{ant-prefix}-collapse-header {
    padding-left: 0;
  }

  .@{ant-prefix}-collapse-content-box {
    padding: 0;
  }
}

.@{ant-prefix}-collapse-borderless
  > .@{ant-prefix}-collapse-item
  > .@{ant-prefix}-collapse-content
  > .@{ant-prefix}-collapse-content-box {
  padding-top: 0;
}

.summary {
  padding: 20px 0;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0 17px;
    border-bottom: var(--border-width-base) var(--border-style-base) var(--border-color-base);

    &-description {
      color: var(--btn-disable-color);
      font-size: 14px;
      line-height: 16px;
      text-align: left;
    }

    &-total {
      color: var(--text-color);
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
    }
  }
}

.desktop-page-side {
  position: relative;

  @media (min-width: @screen-md-min) {
    height: auto;
    padding: 24px;
    border: var(--border-width-base) var(--border-style-base) var(--border-color-base);
    border-radius: var(--border-radius-sm);
  }
}

.mobile {
  @media (min-width: @screen-md-min) {
    display: none;
  }
}

.desktop {
  @media (max-width: @screen-sm-max) {
    display: none;
  }
}

.screen-only {
  @media print {
    display: none;
  }
  &img {
    height: auto !important;
  }
}

.print-only {
  @media screen {
    display: none;
  }
  &img {
    height: auto !important;
  }
}

.@{ant-prefix}-menu.ant-menu-dark {
  background: transparent;
}
// .inverse {
//   color: var(--text-color-inverse);
//   background-color: var(--text-color);
// }

.@{ant-prefix}-btn {
  font-family: var(--font-family-primary);
}

.@{ant-prefix}-layout {
  // overflow-x: hidden;
  &.inverse {
    background-color: var(--text-color);

    * {
      color: var(--text-color-inverse);
    }
  }

  &&.@{ant-prefix}-main-layout-burger {
    > .@{ant-prefix}-layout-header {
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 2;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    }
    > .@{ant-prefix}-layout {
      @media (max-width: @screen-md-max) {
        padding-top: 24px;
      }
    }
    .@{ant-prefix}-layout {
      &:not(.@{ant-prefix}-page-layout) {
        min-height: unset;
      }
    }
  }
  &-header {
    &-nav {
      > a {
        display: inline-flex;
      }
    }
  }
  &-content,
  &-footer {
    padding: 0 40px 40px;
    @media (max-width: @screen-md-max) {
      padding-top: 15px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @-webkit-keyframes roll-in-right {
    0% {
      -webkit-transform: translateX(1000px) rotate(720deg);
      transform: translateX(1000px) rotate(720deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotate(0deg);
      transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
  }
  @keyframes roll-in-right {
    0% {
      -webkit-transform: translateX(1000px) rotate(720deg);
      transform: translateX(1000px) rotate(720deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotate(0deg);
      transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
  }

  @-webkit-keyframes roll-out-right {
    0% {
      -webkit-transform: translateX(0) rotate(0deg);
      transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px) rotate(720deg);
      transform: translateX(1000px) rotate(720deg);
      opacity: 0;
    }
  }
  @keyframes roll-out-right {
    0% {
      -webkit-transform: translateX(0) rotate(0deg);
      transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px) rotate(720deg);
      transform: translateX(1000px) rotate(720deg);
      opacity: 0;
    }
  }

  &-footer {
    z-index: 0;
    padding: @layout-footer-padding;
    border-top: none;

    @media (max-width: @screen-md-max) {
      height: auto;
    }
    .footer-cart {
      position: fixed;
      width: 56px;
      height: 56px;
      right: 10px;
      bottom: 20px;
      z-index: 2;
      -webkit-animation: roll-in-right 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
      animation: roll-in-right 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
      &.footer-cart-hide {
        -webkit-animation: roll-out-right 0.65s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
        animation: roll-out-right 0.65s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
      }
      @media (min-width: @screen-sm) {
        right: @layout-footer-padding;
        bottom: calc(@layout-footer-height - (56px / 2));
      }
      .@{ant-prefix}-affix {
        position: absolute;
        top: 0;
      }
      .@{ant-prefix}-badge {
        position: absolute;
        margin: 0 auto;
        top: 0;
        > .footer-cart-badge {
          width: 56px;
          height: 56px;
          background-color: var(--cart-icon-bg);
          font-size: 24px;
          color: var(--cart-icon-color);
          cursor: pointer;
          margin: 0 auto;
          .@{ant-prefix}icon {
            color: #ffffff;
          }
        }
      }
    }
    .footer-row {
      @media (max-width: @screen-xs-max) {
        flex-flow: column;
        > .@{ant-prefix}-col {
          padding-top: 15px;
          &:first-child {
            padding-top: 0;
          }
        }
      }
    }
    .footer-social {
      justify-content: center;
      .@{ant-prefix}-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
      svg * {
        fill: var(--border-color-focused);
      }
    }

    .@{ant-prefix}-footer-links {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      .@{ant-prefix}-footer-links-item {
        position: relative;
        &::before {
          content: '|';
          position: absolute;
          left: -10px;
          top: 50%;
          transform: translateY(-50%);
          color: var(--text-color-secondary);
        }
        &:first-child {
          &::before {
            content: '';
          }
        }
      }
    }
    .@{ant-prefix}-menu {
      display: flex;
      justify-content: flex-end;
      border-right: 0;

      .@{ant-prefix}-menu-item {
        padding: 0 15px;
        width: unset;
        &::before {
          content: '|';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          color: var(--text-color-secondary);
        }
        &:first-child {
          &::before {
            content: '';
          }
        }
        &:last-child {
          padding-right: 0 !important;
        }
        &:after {
          right: 15px;
          left: 15px;
        }
        a {
          color: var(--text-color-secondary);
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }
  }

  &-content {
    width: 100%;
  }

  &-header,
  &-footer {
    > .@{ant-prefix}-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @media (max-width: @screen-md-max) {
      .footer-social-col {
        order: 2;
      }
    }

    .@{ant-prefix}-logo {
      color: var(--text-color-secondary);
    }
  }

  .@{ant-prefix}-breadcrumb {
    font-weight: 500;
  }

  .@{ant-prefix}-page-sider {
    padding: var(--layout-header-padding);
    border-left: 1px solid var(--border-color-base);
  }

  .@{ant-prefix}-page-footer {
    position: relative;
    z-index: 1;
  }
}

.@{ant-prefix}-layout {
  &.inverse {
    background-color: var(--body-background);
  }
  &-body {
    > .@{ant-prefix}-layout-footer {
      padding: @layout-footer-padding;
      @media (min-width: @screen-md-min) {
        height: @layout-header-height;
        padding: 0 @layout-footer-padding;
        display: flex;
        align-items: center;
        margin-top: auto;
      }
    }
  }
}

.@{ant-prefix}-notification-notice-error {
  background: @alert-error-bg-color;
  color: @white;

  .@{ant-prefix}-notification-notice-message {
    color: @white;
    font-weight: bold;
  }

  .@{ant-prefix}-notification-close-x {
    color: @white;
  }
}
