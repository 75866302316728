@import 'src/vars';

.@{ant-prefix}-password-input.@{ant-prefix}-form-item {
  margin-bottom: 24px;
  .@{ant-prefix}-form-item-explain {
    display: none;
  }
}
.@{ant-prefix}-password-strength {
  &-wrapper {
    margin: -16px auto 24px;
  }

  &-descriptions.@{ant-prefix}-list {
    text-align: left;
  }
  &-indicators {
    display: flex;
    flex-wrap: nowrap;
    gap: 2px;
    margin-bottom: 8px;
  }
  &-indicator {
    height: 10px;
    width: 100%;
    &-none {
      background-color: lightgrey;
    }
    &-1 {
      background-color: #fe940d;
    }
    &-2 {
      background-color: #ffd908;
    }
    &-3 {
      background-color: #cbe11d;
    }
    &-4,
    &-5 {
      background-color: #6ecc3a;
    }
    &:first-child {
      border-top-left-radius: var(--border-radius-base);
      border-bottom-left-radius: var(--border-radius-base);
    }
    &:last-child {
      border-top-right-radius: var(--border-radius-base);
      border-bottom-right-radius: var(--border-radius-base);
    }
  }
}

.@{ant-prefix}-form-item.@{ant-prefix}-input-phone {
  z-index: 2;
  .@{ant-prefix}-form-item-label {
    left: 64px !important;
  }
  .react-tel-input {
    .@{ant-prefix}-input {
      padding-left: 64px;
    }
    .form-control {
      width: 100%;
      line-height: 1.5715;
      height: unset;
    }
    .flag-dropdown {
      display: flex;
      .selected-flag {
        width: 54px;
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s;
        .flag {
          position: relative;
          top: unset;
          margin-top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .arrow {
          margin-top: 0;
          top: 0;
          left: 16px;
        }
      }
    }
  }
}
