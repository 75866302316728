.list-action-search__filter {
  flex-direction: row-reverse;
  align-items: center;
  display: flex;
  justify-content: center;
  color: var(--text-color-secondary);
  .ant-btn {
    display: flex;
    align-items: center;
  }

  &:hover * {
    color: var(--primary-color);
  }

  span:nth-child(2) {
    margin-right: 7px;
    margin-left: 0;
    color: inherit;
  }

  &:hover span:nth-child(2) {
    color: var(--primary-color);
  }

  * {
    color: inherit;
  }
}
