@import 'src/vars';

@search_btn_size: 48px;

.@{ant-prefix}-autocomplete-wrapper {
  .@{ant-prefix}-form-item-control{
    min-width: 250px;
    &-input {
      border: var(--border-width-base) var(--border-style-base) var(--input-border-color);
    }
  }
  .@{ant-prefix}-form-item-label {
    + .@{ant-prefix}-form-item-control {
      .@{ant-prefix}-select,
      .@{ant-prefix}-select-single {
        &-selection-search {
          left: 0 !important;
          &-input {
            height: auto !important;
            color: var(--input-color);
            background-color: var(--input-bg);
            border: var(--border-width-base) var(--border-style-base) var(--input-border-color);
            border-color: var(--input-border-color);
            border-radius: var(--input-border-radius);
            padding: 24px 12px 12px !important;
          }
        }
      }
    }
    
  }
  
  
}

.@{ant-prefix}-list-action-search__wrapper {
  width: 250px;
  display: flex;
  flex-direction: row-reverse;
  border: var(--border-width-base) var(--border-style-base) var(--border-color-base);
  border-radius: var(--border-radius-base);
  transition-duration: 0.3s;
  * {
    border: none !important;
  }
  &:hover,
  &:focus,
  &-focused {
    border-color: var(--input-hover-border-color);
    * {
      border: none !important;
    }
  }
  .@{ant-prefix}-input-search-button {
    background-color: transparent;
    &:hover,
    &:focus {
      background-color: transparent;
    }

  }
}

.@{ant-prefix}-list-action-search {
  &:hover + &__button,
  &:active + &__button,
  &:focus-within + &__button {
    border-color: var(--input-hover-border-color);
  }
  .@{ant-prefix}-select-selector {
    padding: 0 !important;
  }
  .@{ant-prefix}-select-selector .@{ant-prefix}-select-selection-search {
    .@{ant-prefix}-select-selection-search-input {
      height: 100%;
      border: none;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:hover,
      &:active,
      &:focus {
        border-color: var(--input-border-color);
      }
    }
    &.condensed {
      width: 0;
      padding-left: 0;
    }
  }

  &__button {
    // transition: all 0.3s;
    background: white;
    border: 1px solid var(--input-border-color);
    height: var(--search_btn_size);
    border-left: none !important;
    border-top-right-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
    // color: @border-color-focused;
    cursor: pointer;
    padding-right: 22px;
    &:hover {
      border-color: unset;
    }
    + .@{ant-prefix}-select-focused:not(.@{ant-prefix}-select-disabled) {
      box-shadow: none;
    }
    + .@{ant-prefix}-select {
      .@{ant-prefix}-select-selection-search-input{
        cursor: text;
      }
    }
  }
}

.header-search {
  position: relative;
  display: flex;
  align-items: center;
  height: @search_btn_size;
  padding-left: 20px;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--border-color-base);
    content: '';
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    transform: translateY(-50%);
  }

  .@{ant-prefix}-select-single:not(.@{ant-prefix}-select-customize-input),
  .@{ant-prefix}-select:not(.@{ant-prefix}-select-customize-input),
  .@{ant-prefix}-select-focused:not(.@{ant-prefix}-select-customize-input) {
    .@{ant-prefix}-select-selector {
      border: 0;
    }
  }

  &__input {
    width: 100%;
    max-width: 300px;
    font-size: 14px;
    line-height: 16px;

    &::placeholder {
      color: var(--text-color-secondary);
    }
  }

  input {
    line-height: @search_btn_size !important;
  }
}
