@import 'src/vars';

.@{ant-prefix}-modal {
  z-index: 1001;
  &-content {
    background-color: var(--modal-bg);
    border-radius: var(--border-radius-base);
  }
  &-footer {
    background-color: var(--modal-footer-bg);
  }
  &-close {
    color: var(--modal-close-color);
  }
}
