@import 'src/vars';

.@{ant-prefix}-page-header {
  width: 100%;
  padding: 0;

  .ant-page-header-heading-extra {
    margin-top: 24px;
  }

  &-back {
    margin-right: 0px !important;
    margin-left: -15px;
    &-button {
      color: var(--text-color);
      &:visited,
      &:focus,
      &:hover {
        color: var(--text-color);
      }
    }
  }

  &.has-breadcrumb {
    // margin-top: -18px;
    .@{ant-prefix}-breadcrumb + .@{ant-prefix}-page-header-heading {
      margin: 0;
    }
    .@{ant-prefix}-breadcrumb-link {
      font-family: var(--breadcrumb-font-family);
      text-transform: capitalize;
      a {
        color: var(--breadcrumb-link-color);
        &:hover {
          color: var(--breadcrumb-link-color-hover);
        }
      }
      .@{ant-prefix}-typography {
        color: var(--breadcrumb-last-item-color);
      }
    }
  }

  &-heading.@{ant-prefix}-heading-long {
    &-left {
      @media (max-width: @screen-sm-max) {
        margin-bottom: 35px;
      }
    }

    &-extra {
      & .@{ant-prefix}-space-item:first-child,
      & .@{ant-prefix}-select {
        @media (max-width: @screen-sm-max) {
          width: 100%;
        }
      }

      @media (max-width: @screen-sm-max) {
        width: 100%;
      }
    }
  }
  .@{ant-prefix}-page-header-heading {
    &-title {
      margin-top: 0;
      margin-right: 0;
      overflow: visible;
      text-overflow: unset;
      h1 {
        font-weight: var(--page-header-heading-font-weight);
        line-height: 1;
        margin-top: 0;
        font-family: var(--page-header-heading-font-family);
        line-height: 1;
        text-transform: var(--heading-transform);
      }
    }
  }

  .@{ant-prefix}-page-header-heading-left {
    margin: 0;
    overflow: visible;
    margin-top: 10px;
  }
}
