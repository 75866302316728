@import 'src/vars';

.@{ant-prefix}-section-spinner {
  width: 100%;
  height: 100%;
  .@{ant-prefix}-spin {
    &-spinning {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.@{ant-prefix}-spin {
  color: var(--primary-color);
  &-dot-item {
    background-color: var(--primary-color);
  }
}
.@{ant-prefix}-overlay-spinner {
  &-loading {
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: all;
    &-full {
      &::before {
        content: '';
        position: fixed;
        background-color: rgba(255, 255, 255, 0.3);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
    }
    .@{ant-prefix}-spin {
      &-spinning {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        background-color: transparent;
        + * {
          opacity: 0.3;
          cursor: not-allowed;
          * {
            pointer-events: none;
          }
        }
      }
    }
  }
}
.@{ant-prefix}-fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: '';
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  &-container {
    z-index: 5;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}

#ppv {
  .mask-loader {
    width: 100vw;
    height: 100vh;

    .@{ant-prefix}-drawer-content {
      background: unset;
      background-color: unset;
    }
  }
}
