@import 'src/vars';

@layout-prefix-cls: ~'@{ant-prefix}-layout';

.@{ant-prefix}-dark-bg {
  background-color: var(--text-color);
  * {
    color: var(--text-color-inverse);
  }
}

.@{ant-prefix}-page-layout {
  .@{layout-prefix-cls} {
    &.inverse {
      // background-color: var(--text-color);

      * {
        color: var(--text-color-inverse);
      }
    }

    &-content,
    &-footer {
      padding: 24px;
      @media (min-width: @screen-md-min) {
        padding: 0 40px 40px;
      }
    }

    &-footer {
      z-index: 2;
      width: 100%;
      padding: @layout-footer-padding;
      border-top: 1px solid var(--border-color-base);

      @media (max-width: @screen-md-max) {
        height: auto;
      }
    }

    &&-has-sider {
      > .@{layout-prefix-cls},
      > .@{layout-prefix-cls}-content {
        width: 100%;
      }
    }

    &-content {
      // padding-top: 40px;
      width: 100% !important;
      height: fit-content;
    }

    &-header,
    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: auto;
      .@{ant-prefix}-logo {
        color: var(--text-color-secondary);
      }
    }
    &-header {
      padding: 0 24px;
      @media (min-width: @screen-md-min) {
        padding: 20px 40px;
        min-height: var(--layout-header-height);
      }
    }
    .@{ant-prefix}-breadcrumb {
      font-weight: 500;
      font-family: var(--breadcrumb-font-family);
    }

    .@{ant-prefix}-page-sider {
      padding: @layout-header-padding;
      border-left: 1px solid var(--border-color-base);
    }

    .@{ant-prefix}-page-footer {
      position: relative;
      z-index: 1;
      height: auto;
      border-top: 1px solid var(--border-color-base);
    }
  }

  flex-direction: column !important;

  &.@{ant-prefix}-layout-content {
    width: auto !important;
  }

  .@{ant-prefix}-page-header-heading {
    &-title {
      white-space: break-spaces;
    }
  }

  .@{ant-prefix}-layout-header {
    @media (max-width: @screen-lg-max) {
      border-bottom: 0;
    }
  }
}
