@import 'src/vars';

.@{ant-prefix}-compact-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .@{ant-prefix}-form-item {
    &.@{ant-prefix}-group-item {
      margin-bottom: 10px;
      &-first:not(&-last) {
        .@{ant-prefix}-input,
        .@{ant-prefix}-select-selector,
        .@{ant-prefix}-input-affix-wrapper {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      &-middle {
        .@{ant-prefix}-input,
        .@{ant-prefix}-select-selector {
          border-radius: 0;
          border-left: none;
          position: relative;
          --border-color: var(--input-hover-border-color);
          --border-width: var(--border-width-base);
          &:hover,
          &:focus {
            &::before,
            &::after {
              opacity: 1;
            }
          }
          &::before,
          &::after {
            content: '';
            transition: opacity @animation-duration-base;
            opacity: 0;
            position: absolute;
            top: 0;
            width: var(--border-width);
            height: 100%;
            background-color: var(--border-color);
          }
          &::before {
            left: 0;
          }
          &::after {
            right: 0;
          }
        }
      }
      &-last:not(&-first) {
        --border-color: var(--input-hover-border-color);
        --border-width: var(--border-width-base);
        .@{ant-prefix}-input,
        .@{ant-prefix}-select-selector,
        .@{ant-prefix}-input-affix-wrapper {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          position: relative;

          &:hover,
          &:focus {
            &::before {
              opacity: 1;
            }
          }
          &::before {
            content: '';
            transition: opacity @animation-duration-base;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: var(--border-width);
            height: 100%;
            background-color: var(--border-color);
          }
        }
      }
    }
  }
}
