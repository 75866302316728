@import 'src/vars';

.@{ant-prefix}-paywall {
  &-content {
    display: flex;
    align-items: center;
    height: 100%;
    flex-flow: column-reverse;
    @media (min-width: @screen-md) {
      flex-flow: unset;
    }
  }
  .@{ant-prefix}-overlay-spinner {
    width: 100%;
    height: unset;
  }
}
.@{ant-prefix}-btn {
  &.@{ant-prefix}-paywall-logout {
    @media (min-width: @screen-md) {
      position: absolute;
      top: -60px;
      right: 0;
    }
  }
}
