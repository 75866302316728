@import 'src/vars';


.@{ant-prefix}-public {
    &-view-container {
        min-height: 100vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        @media(max-width: @screen-sm-max)
        {
            padding: 24px 0;
            .@{ant-prefix}-split-view {
                background-color: transparent;
            }
        }
    }
    &-left-view-row {
        @media(min-width: @screen-md-min)
        {
            height: calc(100vh - 40px);
        }
    }
    &-left-view-container {
        .@{ant-prefix}-portal-navigation-header-link {
            @media(min-width: @screen-md-min)
            {
                margin-top: 40px;
            }
            
        }
    }
    &-right-view {
        &-container {
            align-items: center;
            justify-content: center;
            display: flex !important;
            padding: 100px 0;
            @media(max-width: @screen-sm-max)
            {
                padding: 0;
                flex: 1 1 100% !important;
            }
        }
        &-content {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            position: relative;
            @media(min-width: @screen-md){
                height: 100%;
            }
            > * {
                width: 100%;
            }
            @media(max-width: @screen-sm-max)
            {
                background-color: rgba(var(--body-background-rgb), var(--card-blur-bg-transparency));
                padding: 32px 0;
                backdrop-filter: blur(10px);
            }
        }
    }
}

.@{ant-prefix}-slogan-carousel {
    overflow: hidden;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &-item {
        height: 100%;
        &-content {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            padding: 24px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 0;
            &-bg {
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.2);
                    background-image: linear-gradient(
                        to top,
                        rgba(0, 0, 0, 0.6),
                        rgba(0, 0, 0, 0) 50%,
                        rgba(0, 0, 0, 0.6) 200%
                    );
                    z-index: 0;
                }
            }
            
        }

        &-text {
            z-index: 1;
            max-width: 493px;
        }
    }
    .@{ant-prefix}-carousel-items {
        height: 100%;
        .@{ant-prefix}-carousel,
        .slick-slider,
        .slick-slide,
        .slick-track,
        .slick-list {
            height: 100%;
        }

        .@{ant-prefix}-carousel {
            .slick-slide {
                max-height: none;
                position: relative;
                margin: 0;
            }
        }
    }
}
