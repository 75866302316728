@import 'src/vars';

.@{ant-prefix}-form-large .@{ant-prefix}-form-item-control-input {
  min-height: 20px;
}

.@{ant-prefix}-switch {
  &-checked {
    background-color: var(--primary-color);
  }
}
