@import 'src/vars';

.@{ant-prefix}-popconfirm {
  .@{ant-prefix}-popover {
    &-inner {
      // padding: 30px;
      // &-content {
      //   padding: 0;
      // }
    }
    &-message {
      display: flex;
      align-items: flex-end;
      &-title {
        padding-left: 10px;
      }
      .@{ant-prefix}icon {
        position: relative;
        top: auto;
      }
    }
  }
}
