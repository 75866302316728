@import 'src/vars';

@padding: 8px 12px 12px;

.@{ant-prefix}-card-badge {
  display: flex;
  width: 72px;
  height: 64px;
  padding: @padding;
  font-weight: 700;
  font-size: var(--badge-font-size);
  font-family: var(--badge-font-family);
  text-align: center;
  text-transform: uppercase;
  border-radius: var(--border-radius-xs);
  &-responsive {
    min-width: 72px;
    min-height: 64px;
  }
  &-primary {
    color: var(--card-badge-color);
    background-color: var(--card-badge-bg);
    .@{ant-prefix}-typography {
      color: var(--card-badge-color);
    }
  }
  &-info {
    color: var(--text-color-inverse);
    background-color: var(--info-color);
    .@{ant-prefix}-typography {
      color: var(--card-badge-color);
    }
  }
  &-warning {
    color: var(--text-color-inverse);
    background-color: var(--warning-color);
    .@{ant-prefix}-typography {
      color: var(--card-badge-color);
    }
  }
  &-danger {
    color: var(--text-color-inverse);
    background-color: var(--danger-color);
    .@{ant-prefix}-typography {
      color: var(--card-badge-color);
    }
  }
}
