@import 'src/vars';

@select-prefix-cls: ~'@{ant-prefix}-select';

.@{select-prefix-cls},
.@{select-prefix-cls}-single {
  &:not(&-disabled):hover {
    .@{select-prefix-cls}-selector {
      border-color: var(--input-hover-border-color);
    }
  }
}

.@{select-prefix-cls},
.@{select-prefix-cls}-single {
  .@{ant-prefix}-select-selection-placeholder {
    line-height: calc(var(--input-height-base)) !important;
  }

  .@{select-prefix-cls}-selector,
  .@{select-prefix-cls}-selector .@{select-prefix-cls}-selection-item {
    padding-top: 0;
  }

  &:not(.@{select-prefix-cls}-customize-input),
  &.@{select-prefix-cls}-customize-input {
    .@{select-prefix-cls}-selector {
      height: var(--input-height-base);
      input {
        height: var(--input-height-base);
      }
      .@{select-prefix-cls}-selection-item {
        line-height: var(--input-height-base);
      }
    }
  }

  &&.@{select-prefix-cls}-sm {
    &:not(.@{select-prefix-cls}-customize-input),
    &.@{select-prefix-cls}-customize-input {
      .@{ant-prefix}-select-selector {
        height: var(--input-height-sm);
        input {
          height: var(--input-height-sm);
        }
        .@{select-prefix-cls}-selection-item {
          line-height: var(--input-height-sm);
        }
      }
    }
  }

  &&.@{select-prefix-cls}-lg {
    &:not(.@{select-prefix-cls}-customize-input),
    &.@{select-prefix-cls}-customize-input {
      .@{select-prefix-cls}-selector {
        font-size: var(--font-size-base);
        height: var(--input-height-lg);
        input {
          height: var(--input-height-lg);
        }
        .@{select-prefix-cls}-selection-item {
          line-height: var(--input-height-lg);
        }
      }
    }
  }
  &&-show-search {
    .@{ant-prefix}-select-selector {
      border: none;
    }
  }
  &-selector {
    color: var(--input-color) !important;
    background-color: var(--input-bg) !important;
    border: var(--border-width-base) var(--border-style-base) var(--input-border-color);
    outline: none;
    &:hover,
    &:active {
      border-color: var(--input-hover-border-color);
    }
  }
  &&-disabled {
    cursor: not-allowed;
    .@{ant-prefix}-select-selector {
      color: var(--input-disabled-color);
      background-color: var(--input-disabled-bg);
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &-focused:not(&-disabled) {
    border-color: var(--border-color-focused);
    box-shadow: none;
    // -webkit-box-shadow: 0 0 0 2px @component-background;
    // box-shadow: 0 0 0 2px @component-background;
  }

  // &-selection-search-input,
  // input {
  //   height: 100%;
  //   cursor: inherit;
  // }

  &-item-option {
    &-selected:not(.@{ant-prefix}-select-item-option-disabled) {
      color: var(--text-color-inverse);
      background-color: var(--primary-color);
    }
  }
}
.@{ant-prefix}-row.@{ant-prefix}-form-item.condensed {
  flex-direction: row;
  .ant-col > {
    width: unset;
    flex: initial;
  }
}

.@{ant-prefix}-row.@{ant-prefix}-form-item.item-sharp div {
  border-radius: 0;
}

.@{ant-prefix}-row.@{ant-prefix}-form-item.item-smooth div {
  border-radius: 4px;
}

.@{ant-prefix}-row.@{ant-prefix}-form-item.item-rounded div {
  border-radius: 30px;
}

.@{ant-prefix}-form-item-explain-error {
  [role='alert'] {
    float: left;
  }
}

.@{ant-prefix}-input-affix-wrapper{
  background-color: var(--input-bg);
}
.@{ant-prefix}-input-affix-wrapper:hover {
  z-index: 0;
  border-color: var(--border-color-inverse);
}

.@{ant-prefix}-input-affix-wrapper-focused,
.@{ant-prefix}-input-affix-wrapper:focus {
  z-index: 0;
  box-shadow: none;
}

.@{ant-prefix}-input-affix-wrapper .@{ant-prefix}-input {
  width: calc(100% - 30px);
}

.@{ant-prefix}-checkbox-wrapper,
.@{ant-prefix}-radio-wrapper {
  color: var(--checkbox-color);
  display: grid;
  grid-column-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 16px 1fr;
  font-weight: 400;
  white-space: initial;
  .@{ant-prefix}-checkbox {
    &-inner {
      background-color: var(--checkbox-bg);
    }
    &-checked {
      .@{ant-prefix}-checkbox-inner {
        background-color: var(--checkbox-check-bg);
        &::after {
          border-color: var(--checkbox-check-color);
        }
      }
    }
  }
}

.@{ant-prefix}-radio-inner {
  border-radius: var(--border-radius-xs);
}

.@{ant-prefix}-radio-inner::after {
  // top: 5px;
  // left: 5px;
  // width: 4px;
  // height: 4px;
  background-color: var(--text-color-inverse);
  border-radius: 0;
}

.@{ant-prefix}-radio-checked .@{ant-prefix}-radio-inner {
  background-color: var(--text-color-inverse);
  border-color: var(--border-color-base) !important;
}

.@{ant-prefix}-radio-input:focus + .@{ant-prefix}-radio-inner {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}

.@{ant-prefix}-input-number {
  background-color: var(--input-bg) !important;
  &-handler {
    &-wrap {
      width: 30px;
      opacity: 1;
    }
    &-down-inner,
    &-up-inner {
      right: 8px;
    }
  }
}

.@{ant-prefix}-input-number-focused,
.@{ant-prefix}-input-number:focus,
.@{ant-prefix}-input-number:hover {
  border-color: var(--border-color-focused);

  .@{ant-prefix}-input-number-handler-wrap {
    border-left: 1px solid var(--border-color-focused);
  }

  .@{ant-prefix}-input-number-handler-down {
    border-top: 1px solid var(--border-color-focused);
  }
}

.@{ant-prefix}-input-number-handler-down-inner svg,
.@{ant-prefix}-input-number-handler-up-inner svg {
  fill: var(--text-color);
}

.@{ant-prefix}-radio-group-large .@{ant-prefix}-radio-button-wrapper {
  height: auto;
  padding: 0;
  border: none;
}

.@{ant-prefix}-radio-button-wrapper-checked:not(.@{ant-prefix}-radio-button-wrapper-disabled):first-child {
  border: none;
}

.@{ant-prefix}-radio-button-wrapper-checked:not(.@{ant-prefix}-radio-button-wrapper-disabled):focus-within {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.option-select {
  display: grid;
  grid-gap: 12px;

  .@{ant-prefix}-radio-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 62px;
    padding: 31px;
    color: var(--btn-disable-color);
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    background-color: var(--border-color-base);
    border: none;
    border-radius: var(--border-radius-base);
    cursor: pointer;
    transition: background-color @animation-duration-base, opacity @animation-duration-base;

    span:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  .@{ant-prefix}-radio-button-wrapper:first-child,
  .@{ant-prefix}-radio-button-wrapper:last-child {
    border: none;
    border-radius: var(--border-radius-sm);
  }

  .@{ant-prefix}-radio-button-wrapper-checked:not(
      [class*=' ant-radio-button-wrapper-disabled']
    ).@{ant-prefix}-radio-button-wrapper:first-child {
    color: var(--text-color-inverse);
    background-color: var(--btn-disable-color);
    border: none;
  }

  .@{ant-prefix}-radio-button-wrapper-checked:not(.@{ant-prefix}-radio-button-wrapper-disabled):hover {
    background: var(--btn-disable-color);
    opacity: 0.75;
  }

  .@{ant-prefix}-radio-button-wrapper-checked:not(.@{ant-prefix}-radio-button-wrapper-disabled) .@{ant-prefix}-image {
    filter: brightness(100);
  }

  .@{ant-prefix}-radio-button-wrapper-checked:not(.@{ant-prefix}-radio-button-wrapper-disabled) {
    color: var(--white);
    background-color: var(--btn-disable-color);
    border: none;
  }
}

.@{ant-prefix}-form-item-explain.@{ant-prefix}-form-item-explain-error {
  color: var(--error-color);
  font-size: 12px;
  line-height: 15px;
}

.@{ant-prefix}-form-item-explain,
.@{ant-prefix}-form-item-extra {
  padding-top: 5px;
  padding-left: 11px;
}

.@{ant-prefix}-form-item-label + .@{ant-prefix}-form-item-control {
  .@{ant-prefix}-select-selector {
    height: calc(var(--input-height-base) + 12px) !important;
    .@{ant-prefix}-select-selection-item {
      padding-top: 6px;
    }
    .@{ant-prefix}-select-selection-item,
    .@{ant-prefix}-select-selection-placeholder {
      line-height: calc(var(--input-height-base) + 12px) !important;
    }
    .@{ant-prefix}-select-selection-placeholder {
      padding-top: 5px;
    }
  }
  .@{ant-prefix}-picker {
    height: auto;
    min-height: var(--input-height-base);
  }
  .@{ant-prefix}-input,
  .@{ant-prefix}-picker {
    padding: 24px 12px 12px;
  }
  .@{ant-prefix}-picker-suffix {
    margin-top: -12px;
  }
  .@{ant-prefix}-input-affix-wrapper {
    padding: 0;
  }
}

// .@{ant-prefix}-input-affix-wrapper {
//   padding: 24px 12px 12px;
// }

.@{ant-prefix}-input {
  color: var(--input-color);
  background-color: var(--input-bg);
  border-color: var(--input-border-color);
  border-radius: var(--input-border-radius);
  padding: 12px 12px;
  &.StripeElement {
    padding: 15px 11px;
  }
  &:hover,
  &:focus {
    border-color: var(--input-hover-border-color);
  }
  &-group {
    &&-compact {
      * {
        &:hover {
          border-color: var(--border-color-base) !important;
        }
      }
      .@{ant-prefix}-row {
        display: flex;
        > .@{ant-prefix}-col {
          padding: 0;
        }
      }
    }
  }
  &[disabled] {
    color: var(--input-disabled-color);
    background-color: var(--input-disabled-bg);
  }
}

.@{ant-prefix}-select {
  &-disabled {
    .@{select-prefix-cls}-selector {
      color: var(--input-disabled-color) !important;
      background-color: var(--input-disabled-bg) !important;
    }
  }
  &-selector {
    border-color: var(--input-border-color);
    background-color: var(--input-bg);
  }
}
.@{ant-prefix}-pagination {
  &-options {
    > .@{ant-prefix}-select {
      .@{ant-prefix}-select-selector {
        height: 36px !important;
      }
      .@{ant-prefix}-select-selection-item {
        line-height: 36px !important;
        padding-top: 0 !important;
      }
    }
  }
}
