@import 'src/vars';

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-weight: 800;
  font-family: var(--font-family-primary);
}
strong {
  font-weight: var(--font-weight-strong);
}
.@{ant-prefix}-typography {
  strong {
    font-weight: var(--font-weight-strong);
  }
  a {
    color: var(--link-color);
  }
  &&-disabled {
    color: var(--disabled-color);
  }
  &&-success {
    color: var(--success-color);
  }
  &&-primary {
    color: var(--primary-color);
  }
  &&-warning {
    color: var(--warning-color);
  }
  &&-info {
    color: var(--info-color);
  }
  &&-danger {
    color: var(--danger-color);
  }

  h1&,
  h1,
  h2&,
  h2 {
    text-transform: var(--heading-transform);
    font-weight: 900;
  }

  h1,
  h1& {
    font-size: var(--heading-1-size);
  }

  h2,
  h2& {
    font-size: var(--heading-2-size);
  }

  h3,
  h3& {
    font-size: var(--heading-3-size);
  }

  h4,
  h4& {
    font-size: var(--heading-4-size);
  }

  h5,
  h5& {
    font-size: var(--heading-5-size);
  }

  h1&,
  h1,
  h2&,
  h2,
  h3&,
  h3,
  h4&,
  h4,
  h5&,
  h5,
  h6&,
  h6 {
    color: var(--heading-color);
    font-weight: var(--heading-font-weight);
    font-family: var(--heading-font-family);
  }
  &&-secondary {
    h2&,
    h2,
    h3&,
    h3,
    h4&,
    h4,
    h5&,
    h5,
    h6&,
    h6 {
      color: var(--heading-secondary-color);
      text-transform: none;
      font-family: var(--heading-secondary-font-family);
      font-weight: var(--heading-secondary-font-weight);
    }
    h2&,
    h2 {
      color: var(--heading-secondary-color);
      font-family: var(--font-family-secondary);
      @media (max-width: @screen-lg-max) {
        font-family: var(--font-family-primary);
      }
    }
  }
}
