@import 'src/vars';

.@{ant-prefix}-collapse {
  &&-expandable {
    border: none !important;
    display: flex;
    background-color: transparent;

    .@{ant-prefix}-collapse {
      &-item {
        display: flex;
        flex-direction: column;
        border-bottom: none !important;
        > .@{ant-prefix}-collapse-header {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: start;
          padding: 0;
          padding-left: 0;
          background-color: transparent;
          .@{ant-prefix}-collapse-arrow {
            position: relative;
            top: unset;
            left: unset;
            right: unset;
            transform: unset;
            margin-left: 12px;
          }
          .@{ant-prefix}-collapse {
            right: -25px;
          }
        }
      }
      &-content {
        border-top: none;
        background-color: transparent;
        &-box {
          padding: 0;
          padding-top: 0;
          padding-bottom: 0;
          .@{ant-prefix}-list {
            &-item {
              border-bottom: none;
              padding: 5px 0 0;
            }
          }
        }
      }
    }
  }
}
