@import 'src/vars';

.@{ant-prefix}-wallet {
  &-card {
    cursor: pointer;
    width: 100%;
    .@{ant-prefix}-avatar {
      transition: opacity 0.35s;
      padding: 25px 5px;
      opacity: 1;
    }
    .@{ant-prefix}-tag {
      pointer-events: none;
    }
    .@{ant-prefix}-card-meta-detail {
      width: 100%;
      .@{ant-prefix}-card-meta-title {
        margin-bottom: 0;
      }
    }
    .@{ant-prefix}-payment-brand {
      svg path {
        transition: fill 0.35s;
      }
    }
    &:hover {
      .@{ant-prefix}-avatar {
        opacity: 0.75;
      }
    }
    &-checkable {
      cursor: pointer;
      padding: 12px !important;
      border: 1px solid var(--border-color-base);
      margin-bottom: 10px;
      background-color: var(--component-background);
      opacity: 0.75;
      transition: opacity 0.3s;
      &:hover {
        opacity: 1;
      }
    }
    &-checked {
      border: 1px solid var(--primary-color) !important;
      opacity: 1;
    }
  }
}
