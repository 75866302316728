@import 'src/vars';

@description-rows: 3;
@padding: 8px;
@cover-height: 180px;

.@{ant-prefix}-card {
  background-color: var(--card-bg);
  border-color: var(--border-color-base);
  border-radius: var(--border-radius-base);
  font-size: var(--font-size-base);
  &:not(&-bordered) {
    .@{ant-prefix}-card-body {
      padding: 0;
    }
  }
  .@{ant-prefix}-typography-ellipsis-multiple-line {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-pack: end;
    -webkit-box-orient: vertical;
    max-height: 64px;
    overflow: hidden;
  }
  .@{ant-prefix}icon {
    color: var(--icon-color);
  }

  // &-inactive {
  //   img {
  //     -webkit-filter: grayscale(100%);
  //     filter: grayscale(100%);
  //   }
  // }

  &.@{ant-prefix}-card-with-badge {
    .@{ant-prefix}-card {
      &-cover {
        height: @cover-height;
        flex: 0 0 40%;
        margin-bottom: 0;
      }

      &-body {
        flex-direction: column;
        justify-content: space-between;
        padding-top: 40px;
        padding-bottom: 40px;
        flex: 0 0 60%;
      }

      &-badge {
        top: 140px;
      }
    }
    .@{ant-prefix}-image {
      position: relative;
      height: 100%;
    }

    .@{ant-prefix}-image-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.@{ant-prefix}-card-with-gallery {
    .@{ant-prefix}-card-cover {
      height: 285px;
      max-width: 100%;
      overflow: hidden;
    }
  }
  &-primary {
    .@{ant-prefix}-card-cover {
      margin-bottom: 16px;
      background-color: #f8f8f8;
      border-top-left-radius: var(--border-radius-base);
      border-top-right-radius: var(--border-radius-base);

      .@{ant-prefix}-image-img {
        object-fit: cover;
        object-position: center center;
        height: 100%;
        // &.@{ant-prefix}-image-placeholder {
        //   object-fit: contain;
        // }
      }

      .@{ant-prefix}-image-error {
        display: flex;
        justify-content: center;
        width: unset;

        .@{ant-prefix}-image-img {
          object-fit: contain;
        }
      }
    }
  }

  & .@{ant-prefix}-card-body-wrapper {
    &.@{ant-prefix}-row {
      & > .@{ant-prefix}-col {
        min-height: 1.5715em;
      }
    }

    &.@{ant-prefix}-space-vertical {
      & > .@{ant-prefix}-space-item {
        min-height: 1.5715em;
      }
    }
  }

  &-secondary {
    text-align: center;

    .@{ant-prefix}-card-cover {
      margin: 24px;
    }
  }

  .@{ant-prefix}-avatar-circle {
    margin-top: 8px;
    border: 8px solid var(--border-color-base);
    border-radius: 50%;
  }

  &-hoverable {
    box-shadow: var(--card-shadow);
  }

  &-hoverable:hover {
    box-shadow: var(--card-hoverable-hover-shadow);
    border-color: var(--card-hoverable-hover-border);
  }

  &-subtitle {
    font-family: var(--card-subtitle-font-family);
  }

  &-meta {
    &-details {
      margin-top: auto;
      font-family: var(--card-meta-font-family);
    }
    &-title {
      margin-bottom: 0;
      h2,
      h3,
      h4,
      h5 {
        margin-bottom: 0;
        white-space: normal;
      }
      &-wrapper {
        font-size: unset;
        h2,
        h3,
        h4,
        h5 {
          font-weight: 900;
          font-size: 16px;
          white-space: pre-wrap;
        }
      }
      + .@{ant-prefix}-card-meta-description {
        margin-top: 8px;
      }
    }
  }

  &&-cover-bordered {
    .@{ant-prefix}-card {
      &-cover {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  &&-equal-height {
    height: 100%;
    .@{ant-prefix}-card {
      &-cover {
        border: 1px solid transparent;
        background: transparent;
        height: @cover-height;
        flex: 1 1 @cover-height;
        height: @cover-height;
        margin-bottom: 0;
        .@{ant-prefix}-image {
          height: @cover-height;
        }
      }
      &-body {
        display: flex;
        flex-direction: column;
        flex: 1 1 calc(100% - @cover-height);
        height: calc(100% - @cover-height);
      }
    }
  }

  .@{ant-prefix}-card-badge {
    position: absolute;
    top: 128px;
    left: calc(@layout-section-padding-horizontal - @padding);
  }

  &&-with-footer {
    .@{ant-prefix}-card-body {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding-bottom: 24px;
      height: calc(100% - @cover-height);
      .@{ant-prefix}-card-meta {
        margin-bottom: 15px;
      }
    }

    &-flush {
      .@{ant-prefix}-card-body {
        padding-bottom: 0;
      }
    }
    .@{ant-prefix}-card-footer {
      font-family: var(--card-footer-font-family);
      margin-bottom: 0;
      margin-top: auto;
      &:not(&-flush) {
        padding-top: 24px;
      }
      &-flush {
        margin-left: -24px;
        margin-right: -24px;
      }
      &-bordered {
        border-top: 1px solid var(--border-color-base);
      }
    }
  }

  &&-flush {
    @media (max-width: @screen-md-max) {
      margin: 0 -24px;
      padding: 24px;
    }
  }
}
