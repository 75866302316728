@import 'src/vars';

.@{ant-prefix}-image {
  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &-placeholder {
    div:not(.@{ant-prefix}-spin):not(.@{ant-prefix}-section-spinner) {
      width: 100%;
      height: 100%;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.@{ant-prefix}-image-preview-operations {
  display: none !important;
}
