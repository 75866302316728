@import 'src/vars';

#root {
  .@{ant-prefix}-layout-header-wrapper {
    top: 0;
    position: fixed;
    width: 100%;
    background: var(--body-background);
    z-index: 2;
    transition: all 0.3s ease;
    padding: 0 24px;
    height: var(--layout-header-mobile-height);
    + .@{ant-prefix}-layout {
      padding-top: calc(var(--layout-header-mobile-height) + 12px);
    }
    @media (min-width: @screen-md-min) {
      padding: 0 40px;
    }
    &-scrolled {
      box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    }
  }
}

.@{ant-prefix}-layout-header {
  display: flex;
  align-items: center;
  line-height: 1;
}

.@{ant-prefix}-header-schedule {
  .injected-svg {
    width: 28px !important;
  }
}

.@{ant-prefix}-layout-header-nav {
  width: 100%;
  height: 100%;
  &-item {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 16px 0;
    &:nth-child(1) {
      justify-content: flex-start;
      flex: 1;
    }

    &:nth-child(2) {
      justify-content: center;
      max-width: 200px;
    }

    &:nth-child(3) {
      justify-content: flex-end;
      flex: 1;
    }
    &-logo {
      padding: 16px;
    }
    .@{ant-prefix}-logo {
      height: 100%;
      max-width: unset;
    }
  }
}

// .@{ant-prefix}-layout-footer {
//   border-top: 1px solid var(--border-color-base);
// }

.@{ant-prefix}-drawer-left {
  .@{ant-prefix}-drawer-close {
    top: 40px;
    right: -32px;
  }
}
