@import 'src/vars';

.@{ant-prefix}-logo {
  cursor: pointer;
  max-width: 300px;
  &-icon,
  &-text,
  .@{ant-prefix}-image {
    height: 100%;
  }
  .@{ant-prefix}-image {
    display: flex;
    align-items: center;
  }
  &-icon,
  &-text {
    &&.@{ant-prefix}-image-img {
      object-fit: contain;
    }
  }
  &-icon {
    &&.@{ant-prefix}-image-img {
      width: auto;
    }
  }
  &-text {
    &&.@{ant-prefix}-image-img {
      display: flex;
      align-items: center;
      max-height: 100%;
      width: auto;
      max-width: 100%;
    }
  }
  .anticon {
    + span {
      opacity: 1;
    }
  }

  .@{ant-prefix}-space-item {
    display: flex;
    align-items: center;
    height: 100%;
    > * {
      height: 100%;
    }
  }
}
