@import 'src/vars';

.infinite-scroll-component {
  overflow: initial !important;
}

.card-grid {
  .@{ant-prefix}-pagination {
    text-align: left;

    &-options {
      position: absolute;
      right: 0;
    }
  }
}
.@{ant-prefix}-list {
  &-pagination {
    text-align: left;
  }
}
.@{ant-prefix}-pagination {
  &-item {
    &-active,
    &:hover {
      border-color: var(--primary-color);
      a {
        color: var(--primary-color);
      }
    }
  }
  &-next,
  &-prev {
    &:hover {
      .@{ant-prefix}-pagination-item-link {
        border-color: var(--primary-color);
        color: var(--primary-color);
      }
    }
  }
}
.@{ant-prefix}-table {
  &-clickable {
    .@{ant-prefix}-table-row {
      cursor: pointer;
    }
  }
}

.@{ant-prefix}-list-vertical .@{ant-prefix}-list-item,
.@{ant-prefix}-list-item {
  &-meta {
    &-title {
      color: var(--text-color);
      font-weight: bold;
    }
    &-description {
      color: var(--text-color-secondary);
      .@{ant-prefix}-typography {
        word-break: break-all;
      }
    }
    &-bordered {
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: var(--border-width-base) var(--border-style-base) var(--border-color-base);
    }
    &:last-child {
      border-bottom: none;
    }
    &-click {
      cursor: pointer;
    }
  }
  &-bordered {
    border-bottom: var(--border-width-base) var(--border-style-base) var(--border-color-base);
    .@{ant-prefix}-list-item-meta {
      margin-bottom: 0;
    }
  }
  &-with-click {
    cursor: pointer;
    pointer-events: all;
    * {
      pointer-events: none;
    }
    .@{ant-prefix}-list-item-meta {
      &-avatar {
        transition: opacity 0.3s;
        opacity: 0.8;
      }
    }
    &:hover {
      .@{ant-prefix}-list-item-meta {
        &-avatar {
          opacity: 1;
        }
      }
    }
  }
}

.pv-list-item {
  &-meta {
    &-title,
    &-title .@{ant-prefix}-typography {
      font-family: var(--list-item-meta-title-font-family);
      color: var(--list-item-meta-title-color);
      font-weight: bold;
      margin-bottom: 0;
    }
    &-description,
    &-description .@{ant-prefix}-typography {
      font-family: var(--list-item-meta-description-font-family);
      color: var(--list-item-meta-description-color);
    }
  }
}
