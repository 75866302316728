@import 'src/vars';

.@{ant-prefix}-descriptions {
  &-row {
    .@{ant-prefix}-descriptions-item {
      &-label {
        color: var(--table-header-color);
      }
      &-content {
        background-color: var(--table-header-bg);
        color: var(--table-header-color);
        * {
          color: var(--table-header-color);
        }
      }
    }
  }
}
