@import 'src/vars';
@import '../../../../node_modules/add-to-calendar-button/assets/css/atcb.css';

#atcb-bgoverlay.atcb-click {
  z-index: 1001;
  &:hover {
    cursor: default;
  }
}
.@{ant-prefix}-add-to-calendar-button {
  z-index: 1001;
}

.atcb {
  &-text {
    font-family: var(--font-family-secondary);
  }
}

.atcb-list.atcb-modal {
  position: relative;
  padding: 24px;
  background: var(--background-color-base);
  min-width: 328px;
  border-radius: 4px;
  padding-top: 100px;
}

.atcb-list.atcb-modal::before {
  content: 'Add To Calendar';
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 22px;
  font-weight: bold;
  font-family: var(--font-family-primary);
}

.atcb-list-item {
  text-align: center;
  font-weight: bold;
  justify-content: space-around;
  margin-bottom: 16px;
  border-radius: var(--border-radius-base) !important;
  background: var(--background-color-base);
  color: var(--text-color-secondary);
  border: 1px solid var(--border-color-base);
  border-top: 1px solid var(--border-color-base) !important;
  box-shadow: none;
  padding: 0.6em;
}

.atcb-list-item-close {
  position: absolute;
  top: 24px;
  right: 24px;
  border-radius: 50% !important;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;

  .atcb-icon {
    display: block;
    margin: 0;
    height: 12px;
  }

  .atcb-icon-close {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    font-weight: bold;
  }

  .atcb-text {
    display: none;
  }
}

.atcb-icon {
  display: none;
}
