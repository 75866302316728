@import 'src/vars';
.@{ant-prefix}-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  line-height: 1;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  vertical-align: top;
  cursor: pointer;
  font-family: var(--tag-font-family);
  font-weight: 700;
  font-size: 14px;
  border: none;
  color: var(--text-color);
  border-top-right-radius: 0;
  .@{ant-prefix}-space-item * {
    margin-bottom: 0;
  }
  &&-disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }
  &-not-checkable {
    cursor: default;
    pointer-events: none;
  }
  &&-asymmetric {
    border-radius: 8px 2px 8px 8px;
    &::before {
      border-radius: 8px 2px 8px 8px;
    }
  }
  &&-default {
    color: var(--tag-default-color);
    background-color: var(--tag-default-bg);
  }
  &&-info {
    position: relative;
    color: var(--info-color) !important;
    background: none !important;
    &::before {
      content: '';
      background-color: var(--info-color);
      opacity: 0.2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &&-error {
    position: relative;
    color: var(--danger-color) !important;
    border-color: rgba(var(--danger-color-rgb), 0.2);
    background-color: rgba(var(--danger-color-rgb), 0.2);
  }
  &&-processing {
    position: relative;
    color: var(--info-color) !important;
    background: none !important;
    &::before {
      border-top-right-radius: 0;
      content: '';
      background-color: var(--info-color);
      opacity: 0.2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &&-primary {
    color: #57806e !important;
    background-color: var(--alert-success-bg-color) !important;
  }
  &&-success {
    color: var(--text-color-inverse);
    background-color: var(--success-color);
  }
  &&-warning {
    color: var(--warning-color) !important;
    background-color: rgba(var(--warning-color-rgb), 0.2) !important;
    border: none;
  }
  &&-ghost {
    background-color: transparent;
    border: 1px solid var(--border-color-base);
  }
  &&-middle {
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
  &&-large {
    height: 35px;
    line-height: 35px;
    font-weight: 700;
    font-size: 14px;
    font-family: var(--font-family-primary);
    border: 1px solid var(--border-color-base);
    padding: 0 8px;
  }
  &&-small {
    // height: 20px;
    font-size: 10px;
  }
  &&-checked {
    color: var(--text-color-inverse);
    background-color: var(--primary-color);
    * {
      color: var(--text-color-inverse);
    }
  }
}
.@{ant-prefix}-tag-ghost {
  &.@{ant-prefix}-tag-checked {
    color: var(--text-color-inverse);
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    * {
      color: var(--text-color-inverse);
    }
  }
}
.@{ant-prefix}-tag-close-icon svg {
  fill: var(--text-color-inverse);
  margin-left: 5px;
  height: 12px !important;
  width: 12px !important;
}
