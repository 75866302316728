@import 'src/vars';

.@{ant-prefix}-section {
  overflow: visible;
  &-title {
    font-family: var(--layout-section-title-font-family);
  }
  + .@{ant-prefix}-section {
    margin-top: 24px;
  }

  .@{ant-prefix}-carousel {
    @media (max-width: @screen-xs-max) {
      // width: 95%;
    }
  }

  & .slick-list {
    @media (max-width: @screen-lg-max) {
      overflow: visible;
    }
  }
}
