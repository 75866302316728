@import 'src/vars';

.@{ant-prefix}-input-routing-number {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    .@{ant-prefix}-input-suffix{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
}