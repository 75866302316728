@import 'src/vars';

.@{ant-prefix}-custom-checkbox {
  &.@{ant-prefix}-checkbox-wrapper {
    width: 100%;
    margin-left: 0;

    .@{ant-prefix}-checkbox + span {
      width: 100%;
    }
  }
}
