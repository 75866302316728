@import 'src/vars';

.@{ant-prefix}-order-item-photo {
  .@{ant-prefix}-image {
    width: 150px;
    height: auto;
  }
}
.@{ant-prefix}-table {
  .@{ant-prefix}-table-row {
    &:hover {
      .@{ant-prefix}-table-cell {
        background-color: var(--table-row-hover-bg);
      }
    }
  }
}

.@{ant-prefix}-statement-summary {
  .@{ant-prefix}-list-header {
    padding-bottom: 0;
    border-bottom: 0;
  }

  &.@{ant-prefix}-list-split .@{ant-prefix}-list-item:last-child {
    border-bottom: unset;
  }
}

.@{ant-prefix}-payment-totals {
  .@{ant-prefix}-typography {
    font-size: 18px;
  }
  &-item {
    padding: 12px;
    background-color: var(--table-header-bg);
  }
}
.@{ant-prefix}-payment-table {
  .ant-image {
    width: 200px;
    height: 100px;
  }
  .@{ant-prefix}-table-summary {
    .@{ant-prefix}-payment-summary-row {
      &:not(:last-child) {
        .@{ant-prefix}-payment-summary-row-item {
          padding-bottom: 0;
        }
      }
      &-item {
        // background-color: var(--table-header-bg);
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 14px;
      }
    }
    > tr {
      > th,
      > td {
        border-bottom: none;
      }
    }
  }
}
