@import 'src/vars';
@import './Title.less';

.@{ant-prefix}-typography {
  color: var(--text-color);
  &-secondary,
  &&-secondary {
    color: var(--text-color-secondary);
  }
  &.has-icon {
    display: inline-flex;
    align-items: flex-start;

    .anticon {
      margin-right: 7px;
      transform: translateY(3px);
    }
  }
  a {
    color: var(--link-color);
    &:hover,
    &:active,
    &:focus {
      color: var(--link-hover-color);
    }
  }
  &-success,
  &&-success {
    & a {
      &:hover {
        color: var(--link-hover-color);
      }
    }
  }

  &&-warning {
    color: var(--warning-color);
  }
  &&-danger {
    color: var(--danger-color);
  }
  &&-info {
    color: var(--info-color);
  }
}
