@import 'src/vars';

@modal-gallery-height: 630px;
@modal-nav-container-height: 50px;
@carousel-item-gap: 8px;

.@{ant-prefix}-gallery {
  &-modal {
    max-width: 1100px !important;
    width: 100% !important;
    .@{ant-prefix}-modal-body {
      padding-top: 60px;
      @media (min-width: @screen-md-min) {
        padding: 60px 120px;
      }
    }
    &-container {
      .@{ant-prefix}-gallery-main {
        height: calc((@modal-gallery-height - @modal-nav-container-height) * 0.8);
      }
      .@{ant-prefix}-gallery-items {
        height: calc((@modal-gallery-height - @modal-nav-container-height) * 0.2);
      }
      .@{ant-prefix}-gallery-item {
        &:first-child {
          flex: 1 1 0;
        }
      }
    }
  }
  &-nav-container {
    height: 50px;
  }
  &-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex !important;
    flex-flow: column;
    gap: 4px;
    overflow: hidden;
    .@{ant-prefix}-carousel {
      margin: 0 -@carousel-item-gap;
      .slick {
        &-track {
          flex-flow: row;
          gap: @carousel-item-gap;
        }
        &-slide {
          margin-left: 0;
          margin-right: 0;
          display: flex;
          flex-direction: column;
          height: auto;
          div {
            height: 100%;
          }
          .@{ant-prefix}-gallery-item {
            height: 100%;
          }
        }
        &-dots {
          &-top {
            top: -28px;
          }
        }
      }
    }
  }
  &-main {
    width: 100%;
    height: 70%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    .@{ant-prefix}-gallery-item {
      flex: 1 1 0 !important;
    }
  }

  &-items {
    width: 100%;
    height: 30%;
    overflow: hidden;
  }

  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1 1 0;
    transition: opacity 0.3s;
    max-width: 100%;
    pointer-events: all;
    &-preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      pointer-events: none;
      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
      }
    }
    &:hover {
      .@{ant-prefix}-gallery-item-preview {
        opacity: 1;
      }
    }
    // &:before,
    // &:after {
    //   position: absolute;
    //   opacity: 0;
    //   transition: opacity 0.3s;
    // }
    // &:before {
    //   content: '';
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: rgba(0, 0, 0, 0.5);
    //   // anticon anticon-eye
    // }
    // &:after {
    //   content: 'Preview';
    //   color: #ffffff;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    // }
    &-active {
      opacity: 0.5;
    }
    &:first-child {
      flex: 2 1 0;
    }
    &-click {
      cursor: pointer;
    }
    img.@{ant-prefix}-gallery-item-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      border-radius: 0;
    }
    &-title {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 12px;
      display: flex;
      align-items: center;
      &-text {
        position: relative;
        z-index: 1;
        margin-bottom: 0 !important;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(var(--body-background-rgb), 0.65);
        backdrop-filter: blur(10px);
      }
    }
  }
}
