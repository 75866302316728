@import 'src/vars';

.@{ant-prefix}-survey-section-row {
  .@{ant-prefix}-row {
    margin-bottom: 0;
  }
  + .@{ant-prefix}-survey-section-row {
    margin-top: 24px;
  }
}

.@{ant-prefix}-form-item {
  &-checkbox {
    .@{ant-prefix}-form-item-control {
      min-width: unset;
    }
  }
  &-radio,
  &-checkbox {
    flex-flow: column;

    .@{ant-prefix}-tag {
      height: unset;
      line-height: 20px;
      white-space: normal;
    }
  }

  .@{ant-prefix}-radio-wrapper {
    &::after {
      display: none;
    }
  }

  &-label&-html-label {
    position: relative !important;
    padding: 0;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    label {
      padding: 0;
    }
  }
}
