@import 'src/vars';

.@{ant-prefix}-picker {
  width: 100%;
  border: var(--border-width-base) var(--border-style-base) var(--border-color-base);
  border-radius: var(--border-radius-base);
  height: var(--input-height-base);
  background-color: var(--input-bg);
  padding-top: 0;
  padding-bottom: 0;
  &-input {
    > input {
      color: var(--input-color);
    }
  }
  &-panel {
    &-container {
      background-color: var(--date-picker-bg);
    }
  }
  &-header-view {
    button {
      color: var(--date-picker-heading-color);
      &:hover,
      &:focus,
      &:active {
        color: var(--primary-color);
      }
    }
  }
  &-cell {
    &-in-view {
      &.@{ant-prefix}-picker-cell {
        &&-in-range {
          --in-range-bg: var(--primary-color-rgb);
          &::before {
            background-color: rgba(var(--in-range-bg), 0.3);
          }
          &&-hover {
            &::before {
              background-color: rgba(var(--in-range-bg), 0.5);
            }
          }
        }
        &-range-hover {
          &::after {
            border-top-color: var(--primary-color);
            border-bottom-color: var(--primary-color);
          }
        }

        &-range-start,
        &-range-end {
          .@{ant-prefix}-picker-cell-inner {
            background-color: var(--primary-color);
          }
        }
      }

      &.@{ant-prefix}-picker-cell-today {
        .@{ant-prefix}-picker-cell-inner::before {
          border: 1px solid var(--border-color-inverse);
          border-radius: 50%;
        }
      }
    }
    &&-inner {
      border-radius: 50%;
    }
    &&-selected &-inner {
      background: var(--primary-color);
    }
  }

  &-content {
    font-weight: 700;

    th {
      color: var(--date-picker-heading-color);
      font-weight: inherit;
    }

    td {
      color: var(--date-picker-cell-color);
      font-weight: inherit;
    }
  }

  // &-clear {
  //   display: none;
  // }

  &-suffix {
    color: var(--text-color) !important;
  }

  &-today-btn {
    color: var(--primary-color);
  }
}
