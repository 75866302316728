@import 'src/vars';


.@{ant-prefix}-global-network-select {
  &-wrapper {
    width: 100%;
    height: 60px;
    position: relative;
    z-index: 1001;
    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.5s ease;
    }
    &-open {
      &::before {
        pointer-events: auto;
        opacity: 0.75;
      }
      .@{ant-prefix}-select-open {
       + div {
        top: 6px !important;
       }
      }
    }
    .@{ant-prefix}-select-arrow {
      right: 24px;
      .@{ant-prefix}icon{
        color: var(--menu-item-active-color);
      }
    }
  }

  // Virtual Dropdown Wrapper
  &-dropdown {
    background-color: var(--menu-bg) !important;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    border-radius: 0;
    padding: 0;
    .@{ant-prefix}-select-item {
      padding: 12px;
      &-option {
        background-color: transparent;
        &-active {
          background-color: transparent;
        }
        &:hover:not(.@{ant-prefix}-select-item-option-disabled) {
          background-color: rgba(var(--menu-item-active-bg-rgb), 0.1) !important;
        }
        &-selected {
          background-color: rgba(var(--menu-item-active-bg-rgb), 0.1) !important;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 4px;
            background-color: var(--menu-item-active-bg);
          }
        }
      }
    }
  }

  // Select Box
  &-selector {
    height: 100%;
    width: 100%;
    .@{ant-prefix}-select {
      &-selector {
        height: 100% !important;
        width: 100%;
        border-radius: 0 !important;
        background-color: var(--menu-item-active-bg) !important;
        border: none !important;
        .@{ant-prefix}-global-network-select-item {
          &-title,
          &-subtitle {
            line-height: 1;
            color: var(--menu-item-active-color);
          }
        }
      }
      &-selection {
        &-search {
          display: none;
        }
        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 32px !important;
        }
      }
    }
  }
  
  // Select Box Item (Option)
  &-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    padding: 0 12px;
    &-title,
    &-subtitle {
      line-height: 1;
      color: var(--menu-item-color);
    }
    &-active {
      .@{ant-prefix}-global-network-select-item {
        &-title,
      &-subtitle {
        color: var(--menu-item-active-color);
      } 
      }
    }
    .@{ant-prefix}-image {
      &-img {
        max-width: 36px;
      }
    }
  }

  &-wrapper {
    &-collapsed {
      .@{ant-prefix}-select {
        &-selection {
          &-item {
            padding-right: 0 !important;
          }
        }
      }
      .@{ant-prefix}-global-network-select-item {
        display: block;
        text-align: center;
        &-text {
          display: none !important;
        }
      }
      .@{ant-prefix}-global-network-select-item {
        &-title {
          display: none !important;
        }
        &-subtitle {
          display: none !important;
        }
      }
    }
  }
}
