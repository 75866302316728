@import 'src/vars';

.@{ant-prefix}-badge-status {
  &-dot {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
      animation: antStatusProcessing 1.2s infinite ease-in-out;
      content: '';
    }
  }

  &-primary {
    background-color: var(--primary-color);
    &::after {
      border: 1px solid var(--primary-color);
    }
  }
  &-processing {
    background-color: var(--info-color);
    &::after {
      border: 1px solid var(--info-color);
    }
  }
  &-warning {
    background-color: var(--warning-color);
    &::after {
      border: 1px solid var(--warning-color);
    }
  }
  &-danger {
    background-color: var(--danger-color);
    &::after {
      border: 1px solid var(--danger-color);
    }
  }
  &-success {
    background-color: var(--success-color);
    &::after {
      border: 1px solid var(--success-color);
    }
  }
}

.@{ant-prefix}-badge {
  &-counter {
    .@{ant-prefix}-badge-count {
      color: var(--badge-count-color);
      background-color: var(--badge-count-bg);
    }
  }
}
