@import 'src/vars';

.@{ant-prefix}-google-maps {
  &-map {
    position: relative;
    border: var(--border-width-base) var(--border-style-base) var(--border-color-base);
    border-radius: var(--border-radius-base);
  }
  &-marker {
    position: absolute;
    transform: translate(0, -50%);
    &-text {
      background-color: white;
      min-width: 150px;
      padding: 12px 24px;
      border-radius: 8px;
    }
  }
}
