@import 'src/vars';

.@{ant-prefix}-card-meta {
  &-with-tag {
    .@{ant-prefix}-card-meta {
      &-detail {
        width: 100%;
      }
      &-description {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
