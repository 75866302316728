@import 'src/vars';

.@{ant-prefix}-radio {
  &-checked {
    .@{ant-prefix}-radio-inner {
      &::after {
        background-color: var(--primary-color);
      }
    }
  }
  &-inner {
    border-radius: 50%;
    &::after {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      top: 3px;
      left: 3px;
    }
  }
}
