@import 'src/vars';

.@{ant-prefix}-portal-sider-header-logo {
  display: flex;
  &-left {
    justify-content: start;
  }
  &-center {
    justify-content: center;
  }
  &-right {
    justify-content: end;
  }
}

.@{ant-prefix}-layout-sider {
  &-collapsed {
    justify-content: center;
    width: @menu-collapsed-width;
  }

  &.inverse {
    a,
    .@{ant-prefix}-text-typography,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--text-color-inverse) !important;
    }
  }

  &.fixed-left {
    > .@{ant-prefix}-layout-sider-children {
      position: fixed;
      top: 0;
      left: 0;
      width: inherit;
      background-color: var(--menu-bg);
    }
  }

  .@{ant-prefix}-layout-header {
    align-content: center;
    line-height: 0;
    background: var(--menu-bg);
  }

  .@{ant-prefix}-sider-footer {
    @media (min-width: @screen-md-min) {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
    }
    width: inherit;
    padding: 0;
    background: var(--layout-footer-background);
    border-top: none;
    .@{ant-prefix}-account-sider-card {
      display: block;
      height: var(--layout-footer-height);
      padding: 36px 28px;
      border-top: 1px solid var(--border-color-base);
      .@{ant-prefix}-card-meta {
        &-title,
        &-description {
          color: var(--menu-item-color);
        }
      }
    }
  }
}

/* Mobile Sider within Drawer */
.@{ant-prefix}-drawer {
  &-mobile-sider {
    .@{ant-prefix}-drawer-header-no-title,
    .@{ant-prefix}-layout-sider-children {
      position: relative !important;
      display: flex;
      flex-direction: column;
      height: 100vh;
    }
    .ant-drawer-body {
      padding-bottom: 0 !important;
    }
  }
}
