@import 'src/vars';

.@{ant-prefix}-payment-card {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  position: relative;
  width: 100%;
  padding: 24px;
  font-family: var(--font-family-primary);
  letter-spacing: 0.8px;
  background: #f8f8f8;
  background: linear-gradient(0deg, #f8f8f8 0%, #f8f8f8 100%);
  border-radius: var(--border-radius-lg);
  opacity: 1;
  overflow: hidden;
  max-width: 350px;
  @media (max-width: @screen-xs-max) {
    // margin: 0 auto;
  }
  &::before {
    content: '';
    position: absolute;
    width: 250%;
    height: 200%;
    left: -88%;
    top: 27%;
    background-color: #787878;
    border-radius: 50%;
    transform: rotate(350deg);
  }
  .card-number {
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    font-size: 14px;
    letter-spacing: 2px;
  }
  .@{ant-prefix}-payment-brand {
    display: flex;
  }
  .@{ant-prefix}-typography {
    text-transform: uppercase;
  }
  &-title.@{ant-prefix}-typography {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 14px;
  }
  h2,
  h3,
  span {
    font-weight: bold;
  }

  h2 {
    font-size: 14px;
    line-height: 20px;
  }

  h3 {
    font-size: 10px;
    line-height: 14px;
  }

  span {
    font-size: 12px;
    line-height: 17px;
  }

  &-visa {
    &::before {
      background: rgb(0, 39, 156);
      background: linear-gradient(0deg, rgba(0, 39, 156, 1) 0%, rgba(24, 27, 96, 1) 100%);
    }
    h2.@{ant-prefix}-typography,
    h3.@{ant-prefix}-typography,
    h4.@{ant-prefix}-typography,
    h5.@{ant-prefix}-typography,
    span {
      color: white;
    }
  }
  &-amex {
    &::before {
      background: #2e77bb;
      background: linear-gradient(90deg, #2e77bb 0%, #1d8ece 100%);
    }
    h2.@{ant-prefix}-typography,
    h3.@{ant-prefix}-typography,
    h4.@{ant-prefix}-typography,
    h5.@{ant-prefix}-typography,
    span {
      color: white;
    }
  }
  &-discover {
    &::before {
      background: rgb(230, 106, 28);
      background: linear-gradient(90deg, rgba(230, 106, 28, 1) 0%, rgb(248, 158, 32) 100%);
    }
    h2.@{ant-prefix}-typography,
    h3.@{ant-prefix}-typography,
    h4.@{ant-prefix}-typography,
    h5.@{ant-prefix}-typography,
    span {
      color: white;
    }
  }
  &-mastercard {
    &::before {
      background: #f79e1b;
      background: linear-gradient(90deg, #f79e1b 0%, #eb001b 100%);
    }
    h2.@{ant-prefix}-typography,
    h3.@{ant-prefix}-typography,
    h4.@{ant-prefix}-typography,
    h5.@{ant-prefix}-typography,
    span {
      color: white;
    }
  }
}
