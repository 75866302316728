@import 'src/vars';

.@{ant-prefix}-layout {
  .@{ant-prefix}-result-page {
    // min-height: calc(100vh - @layout-header-height);
    height: 100%;
  }
  // &.with-footer {
  //   .@{ant-prefix}-result-page {
  //     min-height: calc(100vh - (@layout-header-height * 2));
  //   }
  // }
}

.@{ant-prefix}-drawer-body {
  .@{ant-prefix}-result {
    padding-left: 0;
    padding-right: 0;
  }
}
