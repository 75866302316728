@import 'src/vars';

.@{ant-prefix}-notification {
  &-has-click {
    cursor: pointer;
  }
  &-notice {
    &-info {
      background: var(--toast-info-bg);
      .@{ant-prefix}-notification-notice {
        &-icon-info {
          color: var(--toast-info-icon-color);
        }
        &-message {
          color: var(--toast-info-title-color);
        }
        &-description {
          color: var(--toast-info-message-color);
        }
      }
    }

    &-success {
      background: var(--toast-success-bg);
      .@{ant-prefix}-notification-notice {
        &-icon-success {
          color: var(--toast-success-icon-color);
        }
        &-message {
          color: var(--toast-success-title-color);
        }
        &-description {
          color: var(--toast-success-message-color);
        }
      }
    }

    &-error {
      background: var(--toast-error-bg);
      .@{ant-prefix}-notification-notice {
        &-icon-error {
          color: var(--toast-error-icon-color);
        }
        &-message {
          color: var(--toast-error-title-color);
        }
        &-description {
          color: var(--toast-error-message-color);
        }
      }
    }

    &-warning {
      background: var(--toast-warning-bg);
      .@{ant-prefix}-notification-notice {
        &-icon-warning {
          color: var(--toast-warning-icon-color);
        }
        &-message {
          color: var(--toast-warning-title-color);
        }
        &-description {
          color: var(--toast-warning-message-color);
        }
      }
    }
  }
}
