@import 'src/vars';

@survey-form-prefix: ~'@{ant-prefix}-survey';

.@{survey-form-prefix} {
  &-form {
    .@{survey-form-prefix}-title {
      text-transform: capitalize;
      font-family: var(--font-family-secondary);
    }

    .@{ant-prefix}-form-large .@{ant-prefix}-form-item-control-input {
      min-height: 24px;
    }

    .@{ant-prefix}-form-item.switch .@{ant-prefix}-form-item-control {
      margin-top: 0;
    }

    .@{ant-prefix}-row {
      &.@{ant-prefix}-form-item {
        margin-bottom: 8px;
      }
    }
  }

  .@{ant-prefix}-col:first-of-type .@{survey-form-prefix}-section {
    margin-top: 0;
  }

  .@{survey-form-prefix}-section {
    margin-top: 60px;
  }

  &-upload {
    .@{ant-prefix}-upload-list {
      &-item {
        padding: 20px;
        border: 1px solid var(--info-color);
        &-card-actions-btn {
          opacity: 1;
        }
      }
    }
  }
}
//   .survey-form {
//     .survey-title {
//       text-transform: capitalize;
//       font-family: @secondary-font-family;
//     }

//     .@{ant-prefix}-form-large .@{ant-prefix}-form-item-control-input {
//       min-height: 24px;
//     }

//     .@{ant-prefix}-form-item.switch .@{ant-prefix}-form-item-control {
//       margin-top: 0;
//     }
//   }

//   .ant-col:first-of-type .survey-section {
//     margin-top: 0;
//   }

//   .survey-section {
//     margin-top: 60px;
//   }
// }
