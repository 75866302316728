@import 'src/vars';

.@{ant-prefix}-avatar {
  background-color: var(--avatar-bg);
  color: var(--avatar-color);
  font-size: 18px;
  &-circle {
    display: flex !important;
    align-items: center;
    justify-content: center;
    .@{ant-prefix}icon {
      vertical-align: 0;
    }
  }

  &&-background {
    &-none {
      background-color: transparent;
    }
    &-primary {
      background-color: var(--primary-color);
    }

    &-warning {
      background-color: var(--warning-color);
    }

    &-danger {
      background-color: var(--danger-color);
    }

    &-info {
      background-color: var(--info-color);
    }

    &-light {
      background-color: var(--avatar-bg);
    }

    &-dark {
      background-color: var(--text-color-secondary);
    }
  }
}
