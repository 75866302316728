@import 'src/vars';

.@{ant-prefix}-skeleton {
    &-image {
        line-height: unset;
    }
    &-image-wrapper {
        > span {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
    &-avatar-wrapper {
        > span {
            display: block;
            width: 100%;
            height: 100%;
            .react-loading-skeleton {
                display: block;
                height: 100%;
            }
        }
    }
    &-list-meta {
        .@{ant-prefix}-card-meta-detail{
            width: 100%;
        }
    }
}
