@import 'src/vars';

.@{ant-prefix}-btn {
  font-family: var(--btn-font-family);
  font-weight: var(--btn-font-weight);
  letter-spacing: 0.8px;
  line-height: var(--btn-font-size);
  transition: background-color @animation-duration-base, color @animation-duration-base, border @animation-duration-base;
  background-color: var(--btn-default-bg);
  font-size: var(--btn-font-size);
  letter-spacing: 0.5px;
  text-transform: var(--btn-text-transform);
  
  &&-icon {
    border: none;
    background-color: transparent;
    box-shadow: none;
    .@{ant-prefix}icon {
      margin: 0 auto;
      svg {
        width: 100%;
      height: 100%;
      }
    }
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border:  none;
      color: var(--primary-color);
    }
  }
  
  &-circle {
    padding-bottom: 0;
    & svg {
      transform: translateY(2px);
    }
  }
  &.wide {
    padding-left: 75px;
    padding-right: 75px;
  }
  
  &&-link {
    border: none;
    border-color: transparent;
    background-color: transparent;
    color: var(--primary-color);
    box-shadow: none;
    text-align: left;
    white-space: pre-line;
    font-weight: var(--font-size-base);
    text-transform: none;
    .@{ant-prefix}-typography {
      color: var(--primary-color);
    }
    &.@{ant-prefix}-btn-small {
      font-size: 12px;
    }
    &-anchor {
      height: auto;
      font-weight: var(--font-weight-strong) !important;
      padding: 0;
      * {
        text-decoration: underline;
      }
    }
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }

  &&-text {
    border: none;
    border-color: transparent;
    background-color: transparent;
    color: var(--text-color);
    box-shadow: none;
    text-align: left;
    white-space: pre-line;
    font-weight: var(--font-size-base);
    text-transform: none;
    padding: 0;
    height: auto;
    .@{ant-prefix}-typography {
      color: var(--text-color);
    }
    &.@{ant-prefix}-btn-small {
      font-size: 12px;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
    &.@{ant-prefix}-btn-theme-primary {
      color: var(--primary-color);
    }
  }
  &:not(&-circle) {
    border-radius: var(--btn-border-radius);
  }

  &:focus,
  &:hover {
    color: var(--btn-default-hover-color);
    background-color: var(--btn-default-hover-bg);
    border-color: var(--btn-default-hover-border);
  }

  &&-ghost {
    background-color: var(--btn-ghost-bg);
    border-color: var(--btn-ghost-border);
    color: var(--btn-ghost-color);
    &:focus,
    &:hover {
      background-color: var(--btn-ghost-hover-bg);
      border-color: var(--btn-ghost-hover-border);
      color: var(--btn-ghost-hover-color);
    }
  }

  &&-primary:not([disabled]) {
    background-color: var(--btn-primary-bg);
    color: var(--btn-primary-color);
    border-color: var(--btn-primary-border-color);
    &:focus,
    &:hover {
      background-color: var(--btn-primary-hover-bg);
      color: var(--btn-primary-hover-color);
      border-color: var(--btn-primary-hover-border-color);
    }
  }

  &&-warning:not([disabled]) {
    background-color: var(--warning-color);
    border-color: var(--warning-color);
    &:focus,
    &:hover {
      color: rgba(var(text-color-inverse-rgb), 0.8);
      background-color: rgba(var(--warning-color-rgb), 0.8);
      border-color: rgba(var(--warning-color-rgb), 0.8);
    }
  }

  &&-danger:not([disabled]) {
    background-color: var(--danger-color);
    border-color: var(--danger-color);
    &:focus,
    &:hover {
      color: rgba(var(--text-color-inverse-rgb), 0.8);
      background-color: rgba(var(--danger-color-rgb), 0.8);
      border-color: rgba(var(--danger-color-rgb), 0.8);
    }
  }

  &&-success:not([disabled]) {
    background-color: var(--success-color);
    border-color: var(--success-color);
    &:focus,
    &:hover {
      color: rgba(var(--text-color-inverse-rgb), 0.8);
      background-color: rgba(var(--success-color-rgb), 0.8);
      border-color: rgba(var(--success-color-rgb), 0.8);
    }
  }

  &&-theme {
    &-text:not([disabled]) {
      &:not(.@{ant-prefix}-btn-link):not(.@{ant-prefix}-btn-text) {
        background-color: transparent;
        border-color: var(--text-color);
        color: var(--text-color);
        &:focus,
        &:hover {
          color: rgba(var(--text-color-rgb), 0.8);
          background-color: transparent;
          border-color: rgba(var(--text-color-rgb), 0.8);
        }
      }
      &.@{ant-prefix}-btn-link,
      &.@{ant-prefix}-btn-text {
        color: var(--text-color);
        font-weight: var(--font-weight-base);
        &:focus,
        &:hover {
          color: rgba(var(--text-color-rgb), 0.8);
        }
      }
    }
    &-error:not([disabled]) {
      &:not(.@{ant-prefix}-btn-link):not(.@{ant-prefix}-btn-text) {
        background-color: var(--error-color);
        border-color: var(--error-color);
        &:focus,
        &:hover {
          color: rgba(var(--text-color-inverse-rgb), 0.8);
          background-color: rgba(var(--error-color-rgb), 0.8);
          border-color: rgba(var(--error-color-rgb), 0.8);
        }
      }
      &.@{ant-prefix}-btn-link,
      &.@{ant-prefix}-btn-text{
        color: var(--error-color);
        &:focus,
        &:hover {
          color: rgba(var(--error-color-rgb), 0.8);
        }
      }
    }

    &-outlined:not([disabled]) {
      &.@{ant-prefix}-btn-theme {
        &-text,
        &-error {
          background-color: transparent;
          box-shadow: none;
        }
        &-text {
          color: var(--text-color);
          &:focus,
          &:hover {
            color: rgba(var(--text-color-rgb), 0.8);
            border-color: rgba(var(--text-color-rgb), 0.8);
          }
        }
        &-error {
          color: var(--error-color);
          &:focus,
          &:hover {
            color: rgba(var(--error-color-rgb), 0.8);
            border-color: rgba(var(--error-color-rgb), 0.8);
          }
        }
      }
    }
  }
}

.@{ant-prefix}-input-search {
  &:hover,
  &:focus {
    & .@{ant-prefix}-input-search-button {
      border-color: var(--input-hover-border-color);
    }

    & .@{ant-prefix}-input {
      border-color: var(--input-hover-border-color);
    }
  }

  .@{ant-prefix}-input-search-button {
    background: transparent;
    border-color: var(--input-border-color);
    border-left: 0;

    &:hover,
    &:focus {
      background: transparent;
    }
  }

  .@{ant-prefix}-input {
    border-right: 0;
  }

  .@{ant-prefix}-input-group-addon {
    background: transparent;

    &:hover,
    &:focus {
      background: transparent;
    }
  }
}
