@import 'src/vars';
@primary-color: #57806e;

#ppv {
  .@{ant-prefix}-debug {
    &-wrapper {
      position: fixed;
      right: 0;
      bottom: -1px;
      transform: translateY(100%);
      max-width: 500px;
      width: 100%;
      z-index: 1001;
      transition: transform 0.2s ease-in-out;
      background-color: white;

      .screenshots {
        display: flex;
        align-items: flex-start;

        .screenshot-wrapper {
          height: 220px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          margin-right: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1px;

          &:nth-child(1) {
            width: 102.76px;
          }

          &:nth-child(2) {
            width: 124.63px;
          }

          &:nth-child(3) {
            width: 164.92px;
          }
        }

        .ant-image {
          img {
            height: 100%;
          }
        }
      }

      &-visible {
        transition: transform 0.2s ease-out;
        bottom: 0;
        transform: translateY(0);
      }

      .debug-company-icon {
        width: 32px;
        height: 32px;
        background: rgba(87, 128, 110, 0.85);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-right: 10px;
      }

      .ant-card-body {
        height: 80vh;
        overflow: auto;
      }

      .ant-debug-btn.@{ant-prefix}-btn,
      .ant-debug-btn.@{ant-prefix}-btn:not([disabled]) {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        background-color: rgba(87, 128, 110, 0.85) !important;
        color: white !important;
        border: 1px solid rgba(87, 128, 110, 0.85) !important;
        border-bottom: none;
        height: 32px;
        padding: 4px 12px;
        font-weight: 400;
      }

      .ant-debug-color {
        &-list {
          padding-top: 12px;
          max-width: initial !important;

          .ant-row {
            margin-bottom: 0;
          }
        }
        &-actions {
          position: relative;
          &-search {
            .@{ant-prefix}-input-search-button {
              background-color: rgba(87, 128, 110, 0.85) !important;
              border-color: #e8e7e9 !important;
              &:hover,
              &:focus,
              &:active {
                background-color: lighten(rgba(87, 128, 110, 0.85), 5%) !important;
              }
            }
          }
        }
        &-box {
          height: 20px;
          width: 20px;
          border: 1px solid #e8e7e9;
        }
        &-popover {
          position: absolute;
          z-index: 2;
          right: 0;
          &-top {
            bottom: calc(100% + 24px);
          }
          &-bottom {
            top: 100%;
          }
        }
      }

      &-card {
        height: calc(100vh - 100px);
        overflow: auto;
        background-color: white;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        .@{ant-prefix}-typography {
          color: #212224;
        }
        .@{ant-prefix}-card-body {
          padding-top: 0;
          padding-bottom: 0;
        }
        .@{ant-prefix}icon {
          vertical-align: middle;
        }
        .@{ant-prefix}-list-item {
          &:first-child {
            padding-top: 0;
          }
        }

        .@{ant-prefix}-tabs {
          overflow: unset;
          > .@{ant-prefix}-tabs-nav {
            overflow: unset;
            background-color: white !important;
            position: sticky;
            top: 0;
            z-index: 1;
            margin-bottom: 0;
          }
          &-content-holder {
            padding-top: 24px;
          }
          &-ink-bar {
            background-color: rgba(87, 128, 110, 0.85) !important;
          }
          &-tab {
            .@{ant-prefix}-tabs-tab-btn {
              color: rgba(87, 128, 110, 0.85);
            }
            &:hover {
              .@{ant-prefix}-tabs-tab-btn {
                color: rgba(87, 128, 110, 0.85) !important;
              }
            }
            &-active {
              .@{ant-prefix}-tabs-tab-btn {
                color: rgba(87, 128, 110, 0.85) !important;
              }
            }
          }
        }
      }

      &-item {
        z-index: 0;
        position: relative;
        margin-bottom: 0;

        cursor: pointer;
        .@{ant-prefix}-input {
          color: #212224;
        }
        &-active {
          z-index: 1;
        }
        .block-picker {
          box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px !important;
        }
      }
    }
  }
}
