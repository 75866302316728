@import 'src/vars';
@import 'text/index.less';
@import 'form/index.less';

.@{ant-prefix}-layout:not(.@{ant-prefix}-page-layout) {
  min-height: 100vh;
}

.@{ant-prefix}-layout {
  background-color: inherit;
}
.@{ant-prefix}-tabs {
  &-top {
    .@{ant-prefix}-tabs-nav {
      &:before {
        border-bottom-color: var(--tabs-bar-color);
      }
    }
  }
  &-tab {
    &-btn {
      color: var(--text-color);
    }
    &&-active &-btn,
    &:hover &-btn {
      color: var(--tabs-active-color);
    }
    &&-disabled {
      color: var(--disabled-color);
      cursor: not-allowed;
    }

    &&-disabled &-btn,
    &&-disabled &-remove {
      &:focus,
      &:active {
        color: var(--disabled-color);
      }
    }
    // &-active {
    //   .@{ant-prefix}-tabs-tab-btn {
    //     color: var(--primary-color);
    //   }
    // }
  }
  &-ink-bar {
    background: var(--tabs-active-color);
  }

  // &-tab-btn {
  //   text-shadow: none;
  //   color: @btn-disable-color;
  //   font-size: 14px;
  //   line-height: 16px;
  //   transition: font-weight @animation-duration-base;
  // }

  // &-tab.@{ant-prefix}-tabs-tab-active .@{ant-prefix}-tabs-tab-btn {
  //   text-shadow: none;
  //   // font-weight: 600;
  //   font-size: 14px;
  //   line-height: 16px;
  // }
}
