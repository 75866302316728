@import 'src/vars';

.@{ant-prefix}-picker-time {
  &-panel {
    &-column {
      &::after {
        content: unset !important;
      }
      & > &-panel-cell-selected &-panel-cell-inner {
        color: var(--text-color-inverse) !important;
      }
      li {
        &.@{ant-prefix}-picker-time-cell-selected &-cell-inner {
          background: var(--primary-color);
        }
      }
    }
    &-column > li.@{ant-prefix}-picker-time-panel-cell-selected {
      .@{ant-prefix}-picker-time-panel-cell-inner {
        background: var(--primary-color);
      }
    }
  }

  &-suffix {
    color: var(--text-color);
  }
}

.@{ant-prefix}-time-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  &-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 6px;
    width: 50%;
  }

  &-colon {
    height: 100%;
    max-width: 5%;
    position: relative;
    &::after {
      content: ':';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    }
  }

  &-arrow {
    &-btn {
      &-up {
        transform: rotate(180deg);
      }
      &.@{ant-prefix}-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 100%;
        height: 26px;
        &[disabled] {
          color: var(--disabled-color);
        }
      }
    }
  }
  .@{ant-prefix}-input {
    &.@{ant-prefix}-time-picker-hours,
    &.@{ant-prefix}-time-picker-minutes {
      padding: calc(var(--input-height-base) / 2) 12px;
      height: 48px;
      width: 100%;
      text-align: center;
      pointer-events: none;
      &:hover,
      &:focus,
      &:active {
        border-color: var(--border-color-base);
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  &-period {
    position: relative;
    &-wrapper {
      max-width: 20%;
    }
    .@{ant-prefix}-radio-button {
      + span {
        line-height: 1;
      }
    }
    &&.@{ant-prefix}-radio-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 26px;
      color: var(--input-color);
      padding: 0;
      text-align: center;
      border: var(--border-width-base) var(--border-style-base) var(--border-color-base);
      border-radius: 0;

      &:first-child {
        border-top-left-radius: var(--border-radius-base);
        border-top-right-radius: var(--border-radius-base);
        border-bottom: none;
        border-right-color: var(--border-color-base);
      }
      &:last-child {
        border-bottom-left-radius: var(--border-radius-base);
        border-bottom-right-radius: var(--border-radius-base);
        border-top: none;
        &::before {
          content: unset;
        }
      }
      &-checked {
        background: var(--primary-color);
        color: var(--text-color-inverse);
        border-color: var(--primary-color);
      }
      &:hover:not(&-checked) {
        background: rgba(var(--primary-color-rgb), 0.5);
        color: var(--text-color-inverse);
        border-color: rgba(var(--primary-color-rgb), 0.5);
      }
    }
  }
}
