@import 'src/vars';

.@{ant-prefix}-alert {
  &-message {
    font-weight: var(--font-weight-strong);
  }

  &-fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    .@{ant-prefix}-alert {
      margin-bottom: 0;
      padding-bottom: 40px;
    }
  }
  &-with-description {
    .@{ant-prefix}-alert {
      &-message {
        font-size: 16px;
      }
      &-icon {
        font-size: var(--alert-with-description-icon-size);
      }
    }
  }

  &-info {
    color: var(--alert-info-message-color);
    background-color: var(--alert-info-bg-color);
    border-color: var(--alert-info-border-color);
    & .@{ant-prefix}-alert-message,
    & .@{ant-prefix}-alert-description,
    & .@{ant-prefix}-alert-close-icon,
    & .@{ant-prefix}-alert-icon {
      color: var(--alert-info-message-color);
      .@{ant-prefix}-typography,
      svg {
        color: var(--alert-info-icon-color);
      }
    }
  }

  &-error {
    color: var(--alert-error-message-color);
    border-color: var(--alert-error-border-color);
    background-color: var(--alert-error-bg-color);
    & .@{ant-prefix}-alert-message,
    & .@{ant-prefix}-alert-description,
    & .@{ant-prefix}-alert-close-icon,
    & .@{ant-prefix}-alert-icon {
      color: var(--alert-error-message-color);
      .@{ant-prefix}-typography,
      svg {
        color: var(--alert-error-icon-color);
      }
    }
    .@{ant-prefix}-btn {
      &-ghost {
        color: var(--alert-error-message-color);
      }
      &:disabled {
        color: rgba(var(--alert-error-message-color-rgb), 0.75);
      }
    }
  }

  &-success {
    color: var(--alert-success-message-color);
    border-color: var(--alert-success-border-color);
    background-color: var(--alert-success-bg-color);
    & .@{ant-prefix}-alert-message,
    & .@{ant-prefix}-alert-description,
    & .@{ant-prefix}-alert-close-icon,
    & .@{ant-prefix}-alert-icon {
      color: var(--alert-success-message-color);
      .@{ant-prefix}-typography,
      svg {
        color: var(--alert-success-icon-color);
      }
    }
  }

  &.inverse {
    color: var(--text-color-inverse) !important;
    background-color: var(--text-color);
    border-color: var(--text-color);
    & .@{ant-prefix}-alert-icon {
      color: var(--text-color-inverse) !important;
    }

    & .@{ant-prefix}-alert-content {
      & .@{ant-prefix}-typography span,
      & .@{ant-prefix}-typography {
        color: var(--text-color-inverse) !important;
      }
    }
  }
}
