@import 'src/vars';

.@{ant-prefix}-link {
  color: var(--link-color);

  &-underline {
    text-decoration: underline;
  }

  &-strong {
    font-weight: bolder;
  }

  &:hover {
    color: var(--link-hover-color);
  }

  :active {
    color: var(--link-active-color);
  }
}
