@import 'src/vars';

@dropdown-width: 70px;

.@{ant-prefix}-phone-form-container {
  display: flex;
  flex: 1 1;
  .@{ant-prefix}-input-number {
    width: 100%;
    color: var(--input-color);
    &-input {
      padding: 24px 12px 12px !important;
      height: unset !important;
      
      width: 100%;
    }
    &-handler-wrap {
      display: none;
    }
  }
  .@{ant-prefix}-phone-form-wrapper {
    position: relative;
    display: inline-flex;
    height: 100%;
    flex: 1 1;
    border: var(--border-width-base) var(--border-style-base) var(--input-border-color);
    border-radius: var(--input-border-radius);
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    transition: all 0.3s;
    &:hover,
    &-focused {
      border-color: var(--input-hover-border-color);
    }
    .@{ant-prefix}-phone-form-input {
      border: none;
    }
  }
  .@{ant-prefix}-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }
  .@{ant-prefix}-country-code-value {
    display: flex;
    flex: none;
    padding: 24px 0 12px 12px;
    pointer-events: none;
    background-color: var(--input-bg);
    color: var(--input-color);
    + .@{ant-prefix}-phone-form-input-wrapper {
      position: static;
      .@{ant-prefix}-input-number-input {
        color: var(--input-color);
        padding-left: 6px;
      }
    }
  }
  .@{ant-prefix}-phone-form-input {
    &-wrapper {
      flex: 1;
      margin-bottom: 0;
    }
    &-content {
      flex: 1;
      color: var(--input-color);
      background-color: var(--input-bg);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: var(--input-border-radius);
      border-top-right-radius: var(--input-border-radius);
      border-color: var(--input-border-color);
      border-width: var(--border-width-base);
      border-style: var(--border-style-base);
    }
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .@{ant-prefix}-form-item-label {
    + .@{ant-prefix}-form-item-control {
      .@{ant-prefix}-input-affix-wrapper {
        padding-left: 32px;
      }
    }
  }


  .@{ant-prefix}-phone-code-form-container {
    .@{ant-prefix}-phone-form-select.@{ant-prefix}-select {
      .@{ant-prefix}-select-selector {
        height: calc(var(--input-height-lg) + 12px) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-color: var(--input-border-color);
        border-right-color: transparent !important;
      }
    }
    
    &:hover {
      .@{ant-prefix}-phone-form-select.@{ant-prefix}-select {
        .@{ant-prefix}-select-selector,
        .@{ant-prefix}-select-single-selector{
          border-color: var(--input-hover-border-color) !important;
          border-right-color: transparent !important;
        }
      }
      + .@{ant-prefix}-phone-form-wrapper {
          border-left-color: var(--input-hover-border-color);
      }
    }

    &:hover {
      border-right-color: var(--input-hover-border-color);
      + .@{ant-prefix}-phone-form-input-wrapper {
        .@{ant-prefix}-input-group {
          .@{ant-prefix}-input {
            + .@{ant-prefix}-input-group-addon {
              border-left: 0;
            }
          }
        }
      }
    }
    .@{ant-prefix}-select-selection {
      &-search {
        display: none;
      }
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .@{ant-prefix}-select-arrow {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0;
    }
  }

  .@{ant-prefix}-form-item {
    .@{ant-prefix}-phone-form-select {
      width: @dropdown-width;

      .@{ant-prefix}-select {
        &-selection-item {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 0 !important;
          height: unset;
        }
      }
    }
  }
  .@{ant-prefix}-phone-form-select-content {
    display: flex;
    align-items: center;
    justify-content: center;
    &-title {
      display: none;
    }
  }
}
.@{ant-prefix}-phone-form-select-list {
  width: 200px !important;
  .@{ant-prefix}-phone-form-select-content {
    display: flex;
    align-items: center;
    gap: 8px;
    &-title {
      color: var(--input-color);
    }
  }
}
