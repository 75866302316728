@import 'src/vars';

@control-size-small: 36px;
@control-size-middle: 48px;
@control-size-large: 56px;
@control-color: var(--primary-color);
@control-color-disabled: var(--border-color-base);

.@{ant-prefix}-input-money {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
}

.@{ant-prefix}-input-number-custom {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  user-select: none;
  width: auto !important;
  &,
  &:focus {
    position: relative;
    border: none !important;
    box-shadow: none !important;
  }

  .@{ant-prefix}-input-number-input-wrap,
  .@{ant-prefix}-input-number-input {
    position: relative;
    z-index: 1;
    margin: 0 auto;
  }

  &.@{ant-prefix}-input-number-small {
    width: @control-size-small * 3;
    height: @control-size-small;
    user-select: none;

    &:hover,
    &-focused {
      height: @control-size-small;
    }

    .@{ant-prefix}-input-number-input {
      width: @control-size-small;
      height: @control-size-small;
      margin: 0 @control-size-small;
    }

    .@{ant-prefix}-input-number-handler-up,
    .@{ant-prefix}-input-number-handler-down {
      background-color: transparent;
      &:hover {
        height: @control-size-small !important;
      }
    }

    .@{ant-prefix}-input-number-handler {
      width: @control-size-small !important;
      height: @control-size-small !important;
    }

    .@{ant-prefix}-input-number-handler-wrap .@{ant-prefix}-input-number-handler-down,
    .@{ant-prefix}-input-number-handler-wrap .@{ant-prefix}-input-number-handler-up {
      right: calc(@control-size-small / 3);
    }
  }

  &.@{ant-prefix}-input-number-middle {
    width: @control-size-middle * 3;
    height: @control-size-middle;
    user-select: none;

    &:hover,
    &-focused {
      height: @control-size-middle;
    }

    .@{ant-prefix}-input-number-input {
      width: @control-size-middle;
      height: @control-size-middle;
      margin: 0 @control-size-middle;
    }

    .@{ant-prefix}-input-number-handler-up,
    .@{ant-prefix}-input-number-handler-down {
      &:hover {
        height: @control-size-middle !important;
      }
    }

    .@{ant-prefix}-input-number-handler {
      width: @control-size-middle !important;
      height: @control-size-middle !important;
    }

    .@{ant-prefix}-input-number-handler-wrap .@{ant-prefix}-input-number-handler-down,
    .@{ant-prefix}-input-number-handler-wrap .@{ant-prefix}-input-number-handler-up {
      right: calc(@control-size-middle / 3);
    }
  }

  &.@{ant-prefix}-input-number-large {
    width: @control-size-large * 3;
    height: @control-size-large;
    user-select: none;

    &:hover,
    &-focused {
      height: @control-size-large;
    }

    .@{ant-prefix}-input-number-input {
      width: @control-size-large;
      height: @control-size-large;
      margin: 0 @control-size-large;
    }

    .@{ant-prefix}-input-number-handler-up,
    .@{ant-prefix}-input-number-handler-down {
      &:hover {
        height: @control-size-large !important;
      }
    }

    .@{ant-prefix}-input-number-handler {
      width: @control-size-large !important;
      height: @control-size-large !important;
    }
  }

  .@{ant-prefix}-input-number-input {
    padding: 0;
    text-align: center;
    border: 1px solid var(--input-border-color);
  }

  .@{ant-prefix}-input-number-input-wrap {
    width: auto;
    opacity: 1;
    transition: width 0.3s;
  }

  .@{ant-prefix}-input-number-handler-wrap {
    position: absolute;
    z-index: 2;
    width: 100%;
    background-color: transparent;
    border: none !important;
    opacity: 1;
    transition: width 0.3s;
  }

  .@{ant-prefix}-input-number-handler {
    position: absolute;
    top: 50%;
    background-color: transparent;
    border: 0;
    transform: translateY(-50%);
    color: var(--primary-color);
    &:active,
    &:hover,
    &:focus {
      background: transparent;
    }
  }

  &:hover .@{ant-prefix}-input-number-handler,
  &:focus .@{ant-prefix}-input-number-handler {
    border: 0;
  }

  &.@{ant-prefix}-up-disabled .@{ant-prefix}-input-number-handler-up {
    color: @control-color-disabled;
    cursor: not-allowed;
  }

  &.@{ant-prefix}-down-disabled .@{ant-prefix}-input-number-handler-down {
    color: @control-color-disabled;
    cursor: not-allowed;
  }

  .@{ant-prefix}-input-number-handler-up {
    right: 0;
  }

  .@{ant-prefix}-input-number-handler-down {
    left: 0;
  }
}
