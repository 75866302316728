@import 'src/vars';

.@{ant-prefix}-payment-brand {
  width: 100%;
  line-height: 1;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  padding: 0 4px;
  div {
    height: inherit;
  }
  svg {
    height: 100%;
    width: 100%;
    fill: none;
    display: flex;
  }
  &-white {
    svg {
      path {
        fill: #ffffff !important;
      }
    }
  }

  &-list {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    .@{ant-prefix}-payment-brand {
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
