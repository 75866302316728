@import 'src/vars';

.@{ant-prefix}-typography {
  &&-clickable {
    text-decoration: underline;
    cursor: pointer;
    color: var(--link-color);
    transition: color 0.3s;
    &:hover,
    &:active,
    &:focus {
      color: var(--link-hover-color);
    }
  }
}
