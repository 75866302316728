@import 'src/vars';

.@{ant-prefix}-carousel {
  .slick-track {
    display: flex !important;
    align-items: stretch;
  }

  .slick-list {
    margin: 0 -12px;
  }

  .slick-slide {
    height: inherit;
    margin: 0 12px;
  }
  &-card {
    .@{ant-prefix}-carousel {
      .slick-slide {
        height: auto;
        > div,
        > div > div {
          height: 100%;
        }
      }
    }
  }
  .slick-dots {
    z-index: 0;
    &-bottom {
      position: static;
      display: flex;
      align-items: center;
      height: 30px;
      bottom: -40px;
      margin: 0;
    }
    li {
      button {
        width: 8px !important;
        height: 8px !important;
        border-radius: calc(8px / 2);
        background-color: var(--text-color);
      }

      &.slick-active {
        button {
          width: 20px !important;
          background-color: var(--text-color);
        }
      }
    }
  }
}

@dot-size: 8px;
@nav-height: 30px;

.@{ant-prefix}-carousel {
  &-banner {
    @media (max-width: @screen-sm-max) {
      margin-left: -24px;
      margin-right: -24px;
    }
  }

  &-banner,
  &-card {
    position: relative;
    margin-bottom: 50px;
    // &-with-nav {
    //   margin-bottom: 50px;
    // }
    .@{ant-prefix}-carousel {
      & img,
      .slick {
        &-dots {
          z-index: 0;
          &-bottom {
            position: static;
            display: flex;
            align-items: center;
            height: @nav-height;
            bottom: -40px;
            margin: 0;
          }
          li {
            button {
              width: @dot-size;
              height: @dot-size;
              border-radius: calc(@dot-size / 2);
              background-color: var(--text-color);
            }

            &.slick-active {
              button {
                width: 20px;
                background-color: var(--text-color);
              }
            }
          }
        }
      }
    }

    &&-nav {
      display: flex;
      align-items: center;
      gap: 20px;
      position: absolute;
      right: 0;
      bottom: -40px;
      height: @nav-height;
      z-index: 1;
      &-arrows {
        &-prev,
        &-next {
          &.@{ant-prefix}-btn {
            padding: 0;
            width: 20px;
            height: 20px;
            border: none;
            box-shadow: none;
            background-color: transparent;
            &[disabled] {
              background-color: transparent;
              opacity: 0.5;
            }
            &:hover,
            &:active {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.@{ant-prefix}-carousel-banner {
  position: relative;
  overflow: hidden;
  &-with-nav {
    margin-bottom: 50px;
  }
  &-nav {
    display: flex;
    align-items: center;
    gap: 20px;
    position: absolute;
    right: 0;
    bottom: -40px;
    height: @nav-height;
    z-index: 1;
    &-arrows {
      &-prev,
      &-next {
        &.@{ant-prefix}-btn {
          padding: 0;
          width: 20px;
          height: 20px;
          border: none;
          box-shadow: none;
          background-color: transparent;
          &[disabled] {
            opacity: 0.25;
            background-color: transparent;
          }
          &:hover,
          &:active {
            background-color: transparent;
          }
        }
      }
    }
  }
  .@{ant-prefix}-carousel {
    & img,
    .slick {
      &-slide {
        height: 100%;
        max-height: 450px;
        margin-left: 0;
        margin-right: 0;
        div {
          width: 100%;
          height: 100%;
          min-height: 200px;
          @media (min-width: @screen-lg) {
            min-height: 450px;
          }
        }
        img {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          bottom: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.@{ant-prefix}-carousel-items {
  position: relative;
  &-with-nav {
    margin-bottom: 50px;
  }
  .@{ant-prefix}-carousel {
    & img,
    .slick {
      &-slide {
        height: 100%;
        > div {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
