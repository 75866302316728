@import 'src/vars';
@select-prefix-cls: ~'@{ant-prefix}-select';

.@{ant-prefix}-input-focused,
.@{ant-prefix}-input:focus,
.@{ant-prefix}-select-focused:not(.@{ant-prefix}-select-disabled) .@{ant-prefix}-select-selector,
.@{ant-prefix}-select &:not(&-disabled):hover &-selector,
.@{ant-prefix}-select-focused &-selector,
.@{ant-prefix}-select-open &-selector {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.@{ant-prefix}-form {
  &-large {
    .@{ant-prefix}-form-item-label > label {
      height: auto !important;
    }
    .@{ant-prefix}-form-item-control-input {
      min-height: unset !important;
    }
  }

  &-item {
    position: relative;
    z-index: 1;
    margin: 0;

    &-control-input {
      .@{ant-prefix}-input-group {
        &-addon {
          .@{ant-prefix}-form-item {
            margin-bottom: 0;
            .@{ant-prefix}-select-arrow {
              right: auto;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }

    &-canvas {
      canvas {
        background-color: var(--input-bg);
        width: 100%;
        height: 150px;
        border: var(--border-width-base) solid var(--border-color-base);
      }
    }
    &-upload {
      .@{ant-prefix}-form-item {
        &-label,
        &-control {
          flex: 1 1 100% !important;
          text-align: left;
        }
      }
    }
    &-checkbox,
    &-radio,
    &-yesno {
      .@{ant-prefix}-form-item-control {
        margin-top: 6px;
        margin-bottom: 0 !important;
      }
    }

    &:not(&-checkbox):not(&-radio)&:not(&-yesno) {
      .@{ant-prefix}-form-item-control {
        margin-bottom: 0 !important;
      }
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 8px;
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      transform: translateY(calc(-50% - 10px));
      content: '';
    }

    &-checkbox,
    &-radio,
    &-yesno {
      .@{ant-prefix}-form-item-label {
        width: 100%;
        text-align: left;
      }
      .@{ant-prefix}-form-item-explain {
        padding-top: 25px;
        padding-left: 0;
      }
    }

    .@{ant-prefix}-form-item-label {
      > label {
        position: relative;
        display: inline-flex;
        align-items: center;
        height: 16px;
        padding: 4px;
        line-height: 1;
        color: var(--input-label-color);
        &::after {
          content: unset;
        }
      }
    }
    &:not(&-checkbox):not(&-radio):not(&-yesno):not(&-upload) {
      .@{ant-prefix}-form-item-label {
        position: absolute;
        top: 4px;
        left: 8px;
        right: 4px;
        z-index: 2;
        min-height: 18px;
        line-height: 16px !important;
        background-color: var(--input-bg);
        text-align: left;
        word-wrap: break-word;
        overflow: visible;
        padding-bottom: 0;
      }
    }
  }

  .@{ant-prefix}-item-bordered {
    border-bottom: 1px solid var(--btn-disable-border);
  }
  .@{ant-prefix}-survey-section-row {
    margin-top: 32px;
  }
  .@{ant-prefix}-survey-section-row:first-child {
    margin-top: 0;
  }

  .@{ant-prefix}-radio {
    &-group {
      .@{ant-prefix}-radio-button-wrapper {
        padding: 0 16px;
        background-color: var(--input-bg);
        color: var(--input-color);
        &-checked {
          background-color: var(--checkbox-check-bg);
          color: var(--checkbox-check-color);
        }
      }
    }
  }
}

.@{ant-prefix}-select,
.@{ant-prefix}:not(.@{ant-prefix}-select-customize-input) {
  &-selector {
    background-color: var(--input-bg);
    border-color: var(--border-color-base);
  }
}
