@import 'src/vars';

@drawer-prefix: '@{ant-prefix}-drawer';

#ppv {
  @media (max-width: @screen-md-max) {
    &.@{ant-prefix}-scrolling-effect {
      touch-action: none;
      overflow: hidden;
    }
  }

  .@{ant-prefix}-drawer {
    max-width: 100%;
    box-shadow: none;
    ::-webkit-scrollbar {
      display: none;
    }
    &:not(&-open) {
      pointer-events: none;

      .@{ant-prefix}-drawer-close {
        display: none;
      }
    }
    &-header-close-only {
      background: transparent;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 4;
      pointer-events: none;
      .@{ant-prefix}-drawer-header-title {
        display: block;
      }
      @media (max-width: @screen-sm-max) {
        background: rgb(240, 240, 240);
        background: linear-gradient(0, transparent 0%, rgba(255, 255, 255, 0.5) 100%);
      }
      + .@{ant-prefix}-drawer-body {
        padding: 40px;
        overflow-x: hidden;
        overflow-y: auto;

        @media (max-width: @screen-sm-max) {
          padding: 40px 24px;
        }

        @media (max-width: 768px) {
          padding-bottom: 200px;
        }
      }
    }

    &-header {
      padding: @layout-header-padding;
      padding-bottom: 0 !important;
      border-bottom: none;
      background-color: transparent;
    }

    &-title {
      padding: var(--page-header-padding);
      font-weight: 800;
      font-size: 28px;
      font-family: var(--font-family-primary);
      line-height: @page-header-heading-title;
      @media (max-width: @screen-lg-max) {
        text-transform: uppercase;
      }
    }

    &-content {
      overflow: visible;
      max-width: 100vw;
      background-color: var(--drawer-bg);
      &-wrapper {
        max-width: 100%;
      }
    }

    &-close {
      pointer-events: all;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--drawer-header-close-size);
        height: var(--drawer-header-close-size);
        background-color: var(--drawer-header-close-bg);
        border-radius: 50%;
        transition: background-color 0.5s;
        border: 1px solid var(--border-color-base);
        color: var(--drawer-header-close-color);
      }
      &:hover {
        span {
          background-color: var(--drawer-header-close-hover-bg);
          color: var(--drawer-header-close-hover-color);
        }
      }
    }

    &-top {
      .@{ant-prefix}-drawer-close {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }

    &-left {
      .@{ant-prefix}-drawer-header {
        &-close-only {
          padding: 0;
          .@{ant-prefix}-drawer-close {
            margin: 0;
            position: absolute;
            padding: 0;
            top: 25px;
            @media (min-width: @screen-lg-min) {
              top: 40px;
              right: auto;
              left: 0;
            }
          }
        }
      }
    }
    &-right {
      .@{ant-prefix}-drawer-close {
        position: absolute;
        padding: 0;
        top: 25px;
        right: 25px;
        @media (min-width: @screen-lg-min) {
          top: 40px;
          right: auto;
          left: 0;
        }
      }

      @media (min-width: @screen-lg-min) {
        &.@{ant-prefix}-drawer-open {
          .@{ant-prefix}-drawer-close {
            left: -25px;
          }
        }
      }

      &-small {
        @media (min-width: @screen-lg-min) {
          width: 350px !important;
        }
      }
      &-medium {
        @media (min-width: @screen-lg-min) {
          max-width: 500px !important;
        }
      }
      &-large {
        @media (min-width: @screen-lg-min) {
          width: 650px !important;
        }
      }

      &.@{ant-prefix}-drawer-open {
        max-width: 100%;
        @media (max-width: @screen-md-max) {
          width: 100%;
        }

        .@{ant-prefix}-drawer-close {
          display: block;
        }
      }
    }
  }
}
