@import 'src/vars';

.@{ant-prefix}-drawer-detail {
  &-image {
    height: 215px;
    overflow: hidden;
    .@{ant-prefix}-image {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  &-icon-buttons {
    .@{ant-prefix}-btn {
      padding: 6px;
    }
  }
  &-card {
    .@{ant-prefix}-card-meta-title {
      margin-bottom: 24px;
    }
    .@{ant-prefix}-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      .@{ant-prefix}icon {
        + span {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 8px);
        }
      }
    }
  }
}
