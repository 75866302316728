@import 'src/vars';

.@{ant-prefix}-table {
  background-color: var(--table-bg);
  &-thead {
    > tr {
      > th {
        background-color: var(--table-header-bg);
        color: var(--table-header-color);
        *,
        .@{ant-prefix}-typography,
        .@{ant-prefix}-typography-secondary {
          color: var(--table-header-color);
        }
        &:last-child {
          text-align: right;
        }
        &.@{ant-prefix}-table-cell {
          &:before {
            content: unset !important;
          }
        }
      }
    }
  }
  th.@{ant-prefix}-table-column-sort {
    background: var(--table-header-selected-bg);
    color: var(--table-header-selected-color);
  }
  &-row {
    background-color: var(--table-row-bg);
    color: var(--table-row-color);
    &:hover {
      background-color: var(--table-row-hover-bg);
      color: var(--table-row-hover-color);
    }
  }
  &-row-expand-icon {
    width: 21px;
    height: 21px;
    line-height: 21px;
    border-radius: 50%;
    background-color: var(--table-expand-icon-bg);
    &::before,
    &::after {
      background-color: var(--table-expand-icon-color);
    }

    &::before {
      top: 9px;
      left: 5px;
      width: 9px;
      height: 1px;
    }

    &::after {
      top: 5px;
      left: 9px;
      width: 1px;
      height: 9px;
    }
  }
}
