@import 'src/vars';

.@{ant-prefix}-full-page-gallery {
  &-drawer {
    @media (max-width: @screen-sm-max) {
      .@{ant-prefix}-drawer-body {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
  @media (max-width: @screen-sm-max) {
    &-title {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  &-item {
    flex-direction: column;
    gap: 16px;
    @media (max-width: @screen-sm-max) {
      &-details {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
    &-h {
      @media (min-width: @screen-md-min) {
        flex-direction: row;
        gap: 32px;
        &-r {
          flex-direction: row-reverse;
        }
        .@{ant-prefix}-full-page-gallery-item-image {
          width: 60%;
        }
        .@{ant-prefix}-full-page-gallery-item-details {
          width: 40%;
        }
      }
    }

    .@{ant-prefix}-typography {
      margin-bottom: 0;
    }
    &-image {
      width: 100%;
      height: 250px;
      object-fit: cover;
      object-position: center center;

      @media (min-width: @screen-sm-min) {
        height: 450px;
      }
    }
  }
}
